import React, { useEffect, useState } from 'react'
import SideNavbar from '../AdminNavbar/SideNavbar'
import Topbar from '../AdminNavbar/Topbar'
import "react-datepicker/dist/react-datepicker.css";
import { REPORTPAGELIST } from '../../Service/service';
import { getData } from "../../Service/ApiMethod";

function ReportPageList() {
  const [resData, setResData] = useState()
  
  const getDetail = () => {
    getData(REPORTPAGELIST)
      .then((response) => response.json())
      .then((res) => {
        setResData(res.data);
        console.log("vddsgfgsdfgsdfgs", res.data)
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);
  return (
    <>
      <div id="pcoded" class="pcoded">
        <div class="pcoded-overlay-box"></div>
        <div class="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div class="pcoded-main-container">
            <div class="pcoded-wrapper">
              <SideNavbar />
              <div class="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="pcoded-inner-content">
                  <div class="main-body">
                    <div class="page-wrapper">
                      <div class="page-body">
                        <div className="card">
                          <div className="card-header">
                             {/* <div className="card-header-right">
                              <ul class="list-unstyled card-option">
                                <li><i class="fa fa fa-wrench open-card-option"></i></li>
                                <li><i class="fa fa-window-maximize full-card"></i></li>
                                <li><i class="fa fa-minus minimize-card"></i></li>
                                <li><i class="fa fa-refresh reload-card"></i></li>
                                <li><i class="fa fa-trash close-card"></i></li>
                              </ul>
                            </div> */}

                            <div className='row'>
                              <div className="col-2" style={{top:"1rem"}}>
                                <h5>Products List</h5>
                              </div>
                            </div>
                          </div>
                          <div className="card-block table-border-style">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportPageList
