import React from "react";
import { Link } from "react-router-dom";

function SubAdminSideNavbar() {
  return (
    <>
      <nav className="pcoded-navbar">
        <div className="sidebar_toggle">
          <Link to={""}>
            <i className="icon-close icons"></i>
          </Link>
        </div>
        <div className="pcoded-inner-navbar main-menu pt-5">
          <ul className="pcoded-item pcoded-left-item">
            <li className="active">
              <a
                href={"/sub-admin-dashboard"}
                className="waves-effect waves-dark"
              >
                <span className="pcoded-micon">
                  <i className="ti-home"></i>
                  <b>D</b>
                </span>
                <span className="pcoded-mtext" data-i18n="nav.dash.main">
                  Dashboard
                </span>
                <span className="pcoded-mcaret"></span>
              </a>
            </li>
          </ul>
          <ul className="pcoded-item pcoded-left-item">
            <li className="pcoded-hasmenu">
              <Link to={""} className="waves-effect waves-dark">
                <span className="pcoded-micon">
                  <i className="ti-layout-grid2-alt"></i>
                </span>
                <span
                  className="pcoded-mtext"
                  data-i18n="nav.basic-components.main"
                >
                  Create-Ticket
                </span>
                <span className="pcoded-mcaret"></span>
              </Link>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <Link
                    to={"/create-ticket"}
                    className="waves-effect waves-dark"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-angle-right"></i>
                    </span>
                    <span
                      className="pcoded-mtext"
                      data-i18n="nav.basic-components.alert"
                    >
                      Create-Ticket
                    </span>
                    <span className="pcoded-mcaret"></span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default SubAdminSideNavbar;
