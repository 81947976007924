import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import {
  ADMINALLRIDES,
  ADMINASSIGNRIDESTICKET,
  ADMINBYTICKETRIDES,
} from "../../Service/service";
import { getData, postData } from "../../Service/ApiMethod";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import AllTicketList from "./AllTicketList";

const ListOfRides = (props) => {
  const [checked, setChecked] = useState(false);
  const [text, setText] = useState("");
  const [ticketData, setTicketData] = useState();
  const [rides, setRides] = useState();
  const [message, setMessage] = useState();
  const param1 = useParams();
  console.log("########", param1.userid);

  const handleTicketDeatail = () => {
    getData(ADMINBYTICKETRIDES + param1.userid)
      .then((response) => response.json())
      .then((res) => {
        console.log("!!!!!!!", res.data);
        setTicketData(res.data);
      })
      .catch((err) => {
        // console.log("Error in categories from Post Form", err);
        // console.log(" code Error", err);
      });
  };
  // console.log(typeof resData);
  useEffect(() => {
    handleTicketDeatail();
    // handleClick();
  }, []);
  console.log("rrrrrrrrrrrrrr", rides);

  // const [ticketId, setTicketId] = useState();
  // const [rideId, setRideId] = useState();
  console.log("userid---->", param1);

  const handleClick = async (param, e) => {
    console.log("----->>>>>>", param);
    // e.preventDefault();
    if (checked) {
      let objItem = {
        ticket_id: parseInt(param1.userid),
        // ride_id: param,
        no_rides: rides,
        if_bulk: "1",
      };
      console.log("rides number", objItem);

      await postData(objItem, ADMINASSIGNRIDESTICKET)
        .then((response) => response.json())
        .then((data) => {
          console.log("---post data rides-----", data);
          swal({
            title: "Add Ride!",
            text: "Add Ride  Successfuly",
            icon: "success",
            button: "OK",
          });
        })
        .catch((err) => {
          console.log("Error in categories from Post Form", err);
          console.log(" code Error", err);
        });
    } else {
      let objItem = {
        ticket_id: parseInt(param1.userid),
        ride_id: param,
      };
      console.log(objItem);

      // e.preventDefault();
      await postData(objItem, ADMINASSIGNRIDESTICKET)
        .then((response) => response.json())
        .then((data) => {
          console.log("---post data -----", data);
          swal({
            title: "Good job!",
            text: "Add Ride  Successfully",
            icon: "success",
            button: "OK",
          });
          window.location.reload();
          const status = data?.status;
          if (status === 400) {
            setMessage(data.message);
          } else {
            props.setTotalCost(data?.total_cost);
          }
        })
        .catch((err) => {
          console.log("Error in categories from Post Form", err);
          console.log(" code Error", err);
        });
    }
  };

  return (
    <div className="main-body">
      <div className="page-wrapper" style={{ marginTop: "-4rem" }}>
        <div className="page-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="card o-visible">
                <div className="card-header ">
                  <div className="row">
                    <div className="col-6 mt-2">
                      <h5>List Of Rides</h5>
                    </div>
                    <div className="col-6">
                      <form class="form-inline">
                        <div class="form-group mb-1">
                          <h5>OR</h5>
                        </div>
                        <div class="checkbox-fade fade-in-primary ">
                          <label class="check-task mb-2">
                            <input
                              type="checkbox"
                              value=""
                              id="check"
                              checked={checked}
                              onChange={() => {
                                if (checked) {
                                  setText("");
                                }
                                setChecked(!checked);
                              }}
                            />
                            <span class="cr">
                              <i class="cr-icon fa fa-check txt-default"></i>
                            </span>
                          </label>
                        </div>
                        <div class="form-group mx-sm-1 mb-1">
                          <input
                            class="form-control"
                            placeholder="No Of Rides"
                            type="text"
                            disabled={!checked}
                            // value={text}
                            onChange={(e) => setRides(e.target.value)}
                          />
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary mb-2"
                          disabled={!checked}
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          onClick={(e) => handleClick(e.preventDefault())}
                        >
                          Submit
                        </button>
                        <div className="card-header-right">
                          <ul className="list-unstyled card-option">
                            <li>
                              <i className="fa fa fa-wrench open-card-option"></i>
                            </li>
                            <li>
                              <i className="fa fa-window-maximize full-card"></i>
                            </li>
                            <li>
                              <i className="fa fa-minus minimize-card"></i>
                            </li>
                            <li>
                              <i className="fa fa-refresh reload-card"></i>
                            </li>
                            <li>
                              <i className="fa fa-trash close-card"></i>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-block">
                  <div className="row"></div>
                  <br />
                  {ticketData != undefined
                    ? ticketData.map((item, index) => {
                        return (
                          <div className="row">
                            <div className="col-2">{item.name}</div>
                            <div className="col-2">{item.thrillometer}</div>
                            <div className="col-2">{item.for_ageGroup}</div>
                            <div className="col-6">
                              {item.isAssigned ? (
                                <button class="btn btn-warning mb-2">
                                  Assigned !
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  class="btn btn-primary mb-2"
                                  disabled={checked}
                                  value={text}
                                  onChange={(e) => setText(e.target.value)}
                                  onClick={() => {
                                    handleClick(item.id);
                                  }}
                                >
                                  &nbsp; Assign ! &nbsp;
                                </button>
                              )}
                              {message}
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfRides;
