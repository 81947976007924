import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';
function Register() {


    const ref = useRef(null);
    const navigate = useNavigate()
    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
        navigate("/explore")
      };
    const [modal, setModal] = useState(false);
    const handleClickProfile =() =>{
        navigate("/myprofile")
    }


  return (
    <div>
         <Modal
                    size='s'
                    isOpen={modal}
                    toggle={() => setModal(!modal)}
                >
                    <ModalHeader>
                        Sign in
                        <button class="close-btn border-0" onClick={() => setModal(false)}>
                       <i class="fa fa-close"></i>
                        </button>
                    </ModalHeader>
                    <ModalBody>
                        <form>
                            {/* <!-- Email input --> */}
                            <div class="form-outline mb-2">
                                <input type="email" id="form2Example1" class="form-control" placeholder="Email" />
                                <label class="form-label" for="form2Example1"></label>
                            </div>

                            {/* <!-- Password input --> */}
                            <div class="form-outline mb-4">
                                <input type="password" id="form2Example2" class="form-control " placeholder="Password" />
                            </div>

                            {/* <!-- 2 column grid layout for inline styling --> */}
                            <div class="row mb-4">
                                <div class="col d-flex justify-content-center">
                                    {/* <!-- Checkbox --> */}
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
                                        <label class="form-check-label" for="form2Example31"> Remember me </label>
                                    </div>
                                </div>

                                <div class="col">
                                    {/* <!-- Simple link --> */}
                                    <a href="#!">password?</a>
                                </div>
                            </div>

                            {/* <!-- Submit button --> */}
                            <button type="button" class="btn btn-danger btn-block mb-4 w-100">Sign in</button>

                            {/* <!-- Register buttons --> */}
                            <div class="text-center">
                                <p>Not a member? 
                                     <a href="#!" type="submit" >Register</a></p>
                                <p>or sign up with:</p>
                                <button type="button" class="btn btn-link btn-floating mx-1">
                                    <i class="fa fa-facebook-f"></i>
                                </button>

                                <button type="button" class="btn btn-link btn-floating mx-1">
                                    <i class="fa fa-google"></i>
                                </button>

                                <button type="button" class="btn btn-link btn-floating mx-1">
                                    <i class="fa fa-twitter"></i>
                                </button>

                                <button type="button" class="btn btn-link btn-floating mx-1">
                                    <i class="fa fa-linkedin"></i>
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>

    </div>
  )
}

export default Register