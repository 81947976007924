import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { getData, postData } from '../../Service/ApiMethod';
import { SUBADMINALLRIDES, SUBADMINBOOKTICKET, SUBADMINGETRIDES } from '../../Service/service';
import SubAdminTopbar from './SubAdminTopbar';
import SubAdminSideNavbar from './SubAdminSideNavbar';
import swal from 'sweetalert';

function CreateTicket() {

  const [selectedOption, setSelectedOption] = useState(null);
  const [option, setOption] = useState([])
  const [resData, setResData] = useState()
  const [mobile, setMobile] = useState()
  const [rideId, setRideId] = useState()

  // error validation

  const [errorstate, setErrorState] = useState("");
  const [namestate, setNameState] = useState();


  function myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  const getDetail = () => {
    getData(SUBADMINALLRIDES)
      .then((response) => response.json())
      .then((res) => {
        // console.log("rrrrrrrrrrrrrrr", res.data)
        setOption(res.data)
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  const handleCreateTicket = () => {
    let dataObj = {
      ride_id: rideId,
      mobile: mobile
    };
    postData(dataObj, SUBADMINBOOKTICKET)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        try {
          // console.log("hi tarun",data);
          const status = data?.status;
          // alert(status)
          if (status === 401) {
            setErrorState(data.message);
          } else if (status === 200) {
            setErrorState(
              swal({
                title: "Create Ticket Added!",
                text: "Create Ticket Added Successfully",
                icon: "success",
                button: "OK",
              })
            );
            window.location.reload();
            // handleClick();
          } else if (status === 401) {
            setNameState(data.errors.mobile);
           
          }
        } catch (error) {
          console.log("---", error);
        }


      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);
  const mappedOptions = option.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  const getTicket = (value) => {
    getData(SUBADMINGETRIDES + value)
      .then((response) => response.json())
      .then((res) => {
        console.log("rrrrrrrrrrrrrrr", res.data)
        setResData(res.data)
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  console.log(typeof resData);
  // useEffect(() => {
  //   getTicket();
  // }, []);

  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <SubAdminTopbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SubAdminSideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="card">
                          <div className="card-header">
                            <h5>Create Tickets</h5>
                          </div>
                          <div style={{display:"flex",justifyContent: "center",color: "red",}}>
                            {errorstate}
                          </div>
                          <form className="form-material p-2">
                            <div className="form-group form-primary">
                              <Select defaultValue={selectedOption} search={""} options={mappedOptions} onChange={(val) => {
                                getTicket(val.value)
                                setRideId(val.value)
                              }}
                              // value={selectedOption}
                              />
                            </div>
                          </form>
                        </div>
                        {resData != undefined
                          ? resData?.map((item, index) => {
                            return (
                              <div className="card" >
                                <form className="form-material p-2">
                                  <div className="form-group form-primary">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="d-inline-block align-middle">
                                          <img
                                            src="https://thumbs.dreamstime.com/b/amusement-park-carousel-other-fun-rides-seaside-nj-33708537.jpg"
                                            alt="user image"
                                            style={{ height: "230px", width: "100%" }}
                                          />
                                        </div>
                                      </div>
                                      <div className='col-4'>
                                        <tr className="border-bottom pt-3">
                                          <th className="ps-0 ">Name</th>
                                          <th className="pe-1 text-end sub-title"></th>{item.name}&nbsp;
                                        </tr><br />
                                        <tr className="border-bottom">
                                          <th className="ps-0 ">thrillometer</th>
                                          <th className="pe-1 text-end sub-title"></th>{item.thrillometer}&nbsp;
                                        </tr><br />
                                        <tr className="border-bottom">
                                          <th className="ps-0 ">for_ageGroup</th>
                                          <th className="pe-1 text-end sub-title"></th>{item.for_ageGroup}&nbsp;
                                        </tr><br />
                                        <tr className="border-bottom">
                                          <th className="ps-0 ">price</th>
                                          <th className="pe-1 text-end sub-title"></th>{item.price}&nbsp;
                                        </tr><br />
                                        <tr className="border-bottom">
                                          <th className="ps-0 ">description</th>
                                          <th className="pe-1 text-end sub-title"></th>{item.description}&nbsp;
                                        </tr>
                                      </div>
                                      <div className="col-3 " style={{ display: "flex", alignItems: "center", paddingLeft: "10px", }} >
                                        <div className="form-group form-primary">
                                          <input type="tel" className="form-control pl-1" placeholder="Mobile Number" style={{ width: "144%" }} onChange={(e) => setMobile(e.target.value)} maxLength={10}/>
                                          <span className="form-bar"></span>
                                          <div style={{ color: "red", }}>
                                            {namestate}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-2" style={{ display: "flex", alignItems: "center", paddingLeft: "1px", marginBottom: "10px" }}>
                                        <button className="btn btn-primary" onClick={(e) => handleCreateTicket(e.preventDefault())}>Create</button>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                </form>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateTicket
