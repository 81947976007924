import React, { useEffect, useState } from "react";
import "./manageTicket.css";
import { Link } from "react-router-dom";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import swal from "sweetalert";
import { ADMINGETRIDE, baseUrl, BULKRIDES, TICKETSMANAGE } from "../../Service/service";
import { getData } from "../../Service/ApiMethod";

function ManageTickets() {
  const [modal, setModal] = useState(false);
  const [ticketData, setTicketData] = useState();
  const [childRide, setChildRide] = useState();
  const [rideDetail, setRideDetail] = useState();
  // const [imageUrl, setImageUrl] = useState();
  const [imageLink, setImageLink] = useState();
  const [imageUrl, setImageUrl] = useState();
  const imagePathURL = baseUrl+`/backend/tickets/`;
  const imageURL = baseUrl+`/backend/tickets/`;
  const popup = (id) => {
    console.log("!>>>",id);
    swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete ticket",
            icon: "warning",
            dangerMode: true,
        }).then((willDelete) => {

            if (willDelete) {
              fetch(baseUrl +`/api/admin/delete/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Origin: process.env.ORIGIN,
                        Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
                        host: baseUrl,
                        Accept: "*/*",
                    }
                }).then((result) => {
                    return result.json()
                }).then((data) => {
                  // console.log('data', data)
                    getRideDetail()
                    swal(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                })
            }


        }


        )
  };

  const getDetail = () => {
    getData(BULKRIDES)
      .then((response) => response.json())
      .then((res) => {
        // console.log("eeeee", res.data)
        setTicketData(res.data);
        setImageLink(res.data?.list?.image);
        setChildRide(res.data[0].master_rides);

        // console.log("BBBBB", res.data?.image_path);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  // console.log("hi", userData);
  console.log("--->>>>>>>>>>>>>", childRide);

  useEffect(() => {
    getDetail();
    // getRideDetail()
  }, []);
  const getRideDetail = (id) => {
    getData(ADMINGETRIDE + id)
      .then((response) => response.json())
      .then((res) => {
        // console.log("VVVVVVV", res.data);
        setRideDetail(res.data);
        setImageUrl(res.data?.image_path);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  // console.log("DDDDDD", rideDetail);

  return (
    <>
      <Modal size="s" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader style={{ position: "relative" }}>
          Ride Detail
          <button style={{ position: "absolute", right: "0", top: "0rem", }} class="close-btn border-0"
            onClick={() => setModal(false)}>
            <i class="fa fa-close"></i>
          </button>
        </ModalHeader>

        <ModalBody>
          {rideDetail != undefined ? rideDetail.map((item, index) => {
            const combineUrl = imageURL + "/" + item?.image;
            console.log("combineImageUrl", combineUrl);
            return (
              <div className="row">
                <div className="col-6">
                  <img src={combineUrl} alt="Event-Profile-Image" style={{ width: '100%' }} />
                </div>
                <div className="col-6">
                  <p>
                    <span className="text-danger">Name :</span>
                    {item?.name}
                  </p>
                  <p>
                    <span className="text-danger">Thrillometer :</span>
                    {item?.thrillometer}
                  </p>
                  <p>
                    <span className="text-danger">ForAgeGroup :</span>
                    {item?.for_ageGroup}
                  </p>
                  <p>
                    <span className="text-danger">Description :</span>
                    {item?.description}
                  </p>
                </div>
              </div>
            );
          })
            : null}
        </ModalBody>
      </Modal>

      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                                    <div className="page-block">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="page-header-title">
                                                    <p className="m-b-12">
                                                        <i
                                                            class="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                        ></i>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <ul className="breadcrumb-title">
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            {" "}
                                                            <i className="fa fa-home"></i>{" "}
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Bootstrap Tables
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Basic Tables
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-header"><h5>Manage Tickets List</h5></div>
                              <div className="row p-2">
                                <div className="col-5" style={{ display: "flex", justifyContent: "center", }}>Tickets</div>
                                <div className="col-7" style={{ display: "flex", justifyContent: "center", }}>
                                  Assigned Rides
                                </div>
                              </div>
                              {ticketData != undefined ? ticketData.map((item, index) => {
                                const combineImageUrl = imagePathURL + "/" + item?.image;
                                console.log("combineImageUrl", combineImageUrl);
                                return (
                                  <div className="row p-2">
                                    <div className="col-1" style={{ display: "flex", justifyContent: "center", }}>
                                      {item.id}
                                    </div>
                                    <div className="col-4">
                                      <div class="card  mb-3" id="cardname" style={{ boxshadow: "0 1px 20px 0 rgb(0 0 0 / 8%)" }}>
                                        <div className="row">
                                          <div className="col-6 p-3">
                                            <img
                                              width={160}
                                              // height={160}
                                              src={combineImageUrl} />
                                          </div>

                                          <div class="col-6 p-3 ">
                                            <p>
                                              <span className="text-danger">
                                                Title :
                                              </span>
                                              {item?.heading}
                                            </p>
                                            <p>
                                              <span className="text-danger">
                                                OriginalPrice :
                                              </span>
                                              {item?.original_price}
                                            </p>
                                            <p>
                                              <span className="text-danger">
                                                SalePrice :
                                              </span>
                                              {item?.sale_price}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row col-7">
                                      {childRide != undefined ? childRide.map((item, index) => {
                                        return (
                                          <div className="col-3">

                                            <div class="card  mb-3" id="cardname"
                                              style={{ boxshadow: "0 1px 20px 0 rgb(0 0 0 / 8%)", position: "relative", }}>
                                              <div
                                                className=""
                                                style={{
                                                  position: "absolute",
                                                  right: "0",
                                                  top: "-0.5rem",
                                                }}
                                              >
                                                <span class="pull-right clickable close-icon" data-effect="fadeOut">
                                                  <span id="clsbtnn" aria-hidden="true" onClick={() => popup(item.id)}>×</span>
                                                </span>
                                              </div>
                                              <div
                                                className="p-2"
                                                onClick={() => {
                                                  setModal(true);
                                                  getRideDetail(
                                                    item.id
                                                  );
                                                  console.log(
                                                    "IDIDIDIDI",
                                                    item.id
                                                  );
                                                }}
                                              >
                                                <p>
                                                  <span className="text-danger">
                                                    Title :
                                                  </span>
                                                  {item?.name}
                                                </p>
                                                <p>
                                                  <span className="text-danger">
                                                    Price :
                                                  </span>
                                                  {item?.price}
                                                </p>
                                              </div>
                                            </div>
                                          </div>);
                                      })
                                        : null}

                                    </div>

                                  </div>
                                );
                              })
                                : null}
                              <hr />
                              <div className="row p-2">
                                <div className="col-1" style={{ display: "flex", justifyContent: "center", }}>
                                  #
                                </div>
                                <div className="col-4">
                                  <div class="card  mb-3" id="cardname" style={{ boxshadow: "0 1px 20px 0 rgb(0 0 0 / 8%)" }}>
                                    <div className="row">
                                      <div className="col-6 p-3">
                                        <img width={100}
                                          src="https://i.pinimg.com/564x/d3/41/8dd3418d97868daa50740564a551c90829.jpg" alt="Event-Profile-Image" />
                                      </div>
                                      <div class="col-6 p-3 ">
                                        <p>
                                          <span className="text-danger">Title :</span> Deal Of The Day
                                        </p>
                                        <p>
                                          <span className="text-danger">OriginalPrice :</span>9900
                                        </p>
                                        <p>
                                          <span className="text-danger">SalePrice :</span>750
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row col-7">
                                  <div className="col-12 pt-5" style={{ display: "flex", justifyContent: "center" }}>
                                    <p>
                                      <span className="text-danger">Number Of Rides Can Be Used :</span>15 NOS
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTickets;
