import React from 'react'
import Adminnavbar from '../AdminNavbar/Dashboard'

const Dashboard = () => {
  return (
    <div>
      <Adminnavbar />
    </div>
  )
}

export default Dashboard
