import React, { useState, useRef, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import "./Navbar.css";
// import "./script";
import ahom_logo from "./Imgs/logo.png";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
// import { Link, useNavigate } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import ImageSlid from "./ImageSlider/ImageSlid";
import Info from "./Info";
import Register from "./Register";
// import Explore from './Explore';
import { getData, getData1, postData } from "../Service/ApiMethod";
import { USERLOGIN, USERREGISTER , GOOGLEAUTH} from "../Service/service";
import swal from "sweetalert";
import Protected from "./Protected/Protected";
import Protect from "./Dashboard/Protect";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// import { GoogleLogin } from 'react-google-login';
// const clientId = 'YOUR_CLIENT_ID_GOES_HERE';

function Navnbar() {
  // const { loginWithRedirect } = useAuth0();
  // const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [register, setRegister] = useState(false);
  const [message, setMessage] = useState("");
  
  const [regName, setRegName] = useState();
  const [regEmail, setRegEmail] = useState();
  const [regPassword, setRegPassword] = useState();

  const [passwordConfirmation, setPasswordCofirmation] = useState();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [errorstate, setErrorState] = useState("");
  const [name, setName] = useState();
  const [emailid, setEmailId] = useState();
  const [passwordid, setErrorPassword] = useState();
  const [repassword, setRePassword] = useState();
  const [descriptionstate, setDescriptionState] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  // const [rememberMe, setRememberMe] = useState(false);

  const localValue = localStorage.getItem("isLoggedIn");
  const { loginWithRedirect } = useAuth0();

  const handleReagister = async (e) => {
    // setIsLoading(true)
    setModal(false)
    let regItem = {
      name: regName,
      email: regEmail,
      password: regPassword,
      userType: "2",
      password_confirmation: passwordConfirmation,
    };
    // console.log("static", regItem);
    // setIsAuthenticated(true);
    await postData(regItem, USERREGISTER)
      .then((res) => res.json())

      .then((data) => {
        try {
          console.log("From Api", data);
          const token = data?.token;
          const userType = data?.userType;
          const status = data?.status;
          localStorage.setItem("fun_park_token", token);

          if (token !== undefined && userType === 2) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("fun_park_token", token);
            localStorage.setItem("rememberMe", "true");
            navigateToLogin();
            setIsAuthenticated(false);

          
          // if (token !== undefined && userType == 2) {
          //   localStorage.setItem("fun_park_token", token);
          //   localStorage.setItem("isLoggedIn", true);
          //   navigateToExplore()
           
          } else if (status === 400) {
            setErrorState(data.message);
          } else if (status === 200) {
           
            swal({
              title: "Good job!",
              text: "User Register Successfuly",
              icon: "success",
              button: "OK",
            });

            
            
             setModal(true) 
          } else if (data.stauts === 401) {
            setIsLoading(true);
            setName(data.errors.name);
            setEmailId(data.errors.email);
            setErrorPassword(data.errors.password);
            setRePassword(data.errors.password_confirmation);
            // setNameState(data.errors.name)
          } else {
            // alert(data?.error_message);
            setMessage("Please Enter Correct Email or Password");
          }
        } catch (err) {
         
          console.log("error hgappended", err);
          setError(err.message, "email already exists");
          setMessage(err.message, "email already exists");
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          // alert("Error", err);
        }
      });

    // setRegister(false)
  };

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    navigate("/explore");
  };

  const navigateToExplore = () => {
    navigate("/explore");
  };


  const handleClickProfile = () => {
    navigate("/myprofile");
  };

  const nav = useNavigate();

  const navigateToLogin = () => {
    nav("/explore");
  };
  const handleClickOrder = () => {
    navigate("/myorder");
  };

  const handleClickHome = () => {
    navigate("/");
  };

  const handleLogin = async () => {
    let objItem = {
      email: email,
      password: password,
    };

    // console.log("static", objItem);
    await postData(objItem, USERLOGIN)
      .then((res) => res.json())

      .then((data) => {
        try {
          // console.log("From Api", data);
          const token = data?.token;
          const userType = data?.userType;
          // localStorage.setItem("fun_park_token", token);
          // localStorage.setItem("isLoggedIn", true);
          if (token !== undefined && userType === 2) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("fun_park_token", token);
            localStorage.setItem("rememberMe", "true");
            navigateToLogin();
            setIsAuthenticated(false);
            // setIsLoggedIn(true);
          } else {
            setMessage("Please Enter Correct Email or Password");
            localStorage.removeItem("rememberMe");
          }
        } catch (err) {
          // console.log("error hgappended", err);
          setError(err.message, "email already exists");
          // alert("Error", err);
        }
      });
  };
  useEffect(() => {
    // getData1(GOOGLEAUTH)
    //     .then((response) => response.json())
    //     .then((res) => {
    //       setGoogleLink(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     });
  });

  // useEffect(() => {
  //     const rememberMeFlag = localStorage.getItem("rememberMe");
  //     if (rememberMeFlag === "true") {
  //       setRememberMe(true);
  //     }
  //   }, []);
  // function handleLogout() {
  //     // Clear the token
  //     localStorage.clear();

  //     // Update state to reflect the user being logged out
  //     setIsLoggedIn(false);
  //   }
  const navigateto = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigateto("/");
    setIsAuthenticated(false);
  };
  // function handleRememberMeChange(event) {
  //     setRememberMe(event.target.checked);
  //   }
  const login_logout = localStorage.getItem("isLoggedIn");

  // const login = () => {

  //     navigateto("/");
  //     setIsAuthenticated(false);
  // };
  // const { logout1 } = useAuth0();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };


  

  // const onSuccess = (response) => {
  //   console.log('Login success. Response:', response);
  // };

  // const onFailure = (response) => {
  //   console.log('Login failed. Response:', response);
  // };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-tertiary"
        style={{ padding: "0%" }}
      >
        <div
          className="container-fluid nav-bg"
          style={{
            height: "100px",
            boxShadow: "rgb(0 0 0 / 58%) 0px 5px 19px",
          }}
        >
          <a className="navbar-brand" href="#">
            <img
              src={ahom_logo}
              alt="logo.jpg"
              width="180px"
              style={{ marginLeft: "25px" }}
            />
          </a>

          {/* <img src="https://res.cloudinary.com/simplotel/image/upload/x_-1,y_100,w_843,h_376,r_0,c_crop,q_80,dpr_1,f_auto,fl_progressive/w_355,h_200,f_auto,c_fit/wonderla-amusement-parks-resort/Light_Logo_njgie5" alt="logo.jpg" width="180px" style={{marginLeft:"30px"}}  /></a> */}

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ backgroundColor: "#e7a20c" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav me-auto mb-2 mb-lg-0"
              style={{ textDecoration: "none" }}
            >
              <li className="nav-item text-light">
                <a
                  className="nav-link"
                  aria-current="page"
                  href=""
                  onClick={handleClickHome}
                >
                  Home
                </a>
                {/* <a href={"/"} >Home</a> */}
              </li>
              <li className="nav-item">
                {/* <Link to='/explore'>
                                <span>Explore</span>
                            </Link> */}
                <a className="nav-link" href="#" onClick={handleClick}>
                  Explore
                </a>
              </li>
              {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </a>
                            <ul className="dropdown-menu bg-danger rounded-0">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li> */}
              {/* <li className="nav-item">
                            <a className="nav-link">Contact Us</a>
                        </li> */}

              {localValue && (
                <li className="nav-item">
                  <a className="nav-link" href="" onClick={handleClickOrder}>
                    My Orders
                  </a>
                </li>
              )}
            </ul>
          </div>
          {/* { register ?
                <Register/> :null } */}

          {/* <div className="search-container">
            <form className="d-flex" role="search">
              <input
                type="search"
                className="search-input form-control rounded-1"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
              <span
                className="search-button input-group-text border-0 bg-dark text-light rounded-0"
                id="search-addon"
              >
                <i className="fa fa-search"></i>
              </span>
            </form>
          </div> */}

          <Modal size="s" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader>
              Sign in
              <button
                className="close-btn bg-dark text-white border-0"
                onClick={() => setModal(false)}
              >
                <i className="fa fa-close"></i>
              </button>
              {/* <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button> */}
            </ModalHeader>

            <ModalBody>
              
              
              <form>
                {/* <div className="text-danger text-center">{message}</div> */}
                <div style={{ display: "flex", justifyContent: "center", color: "red" }}>{errorstate}</div>

                {/* <!-- Email input --> */}
                <div className="form-outline mb-2">
                  <input
                    type="email"
                    id="form2Example1"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <label className="form-label" for="form2Example1"></label>
                </div>

                {/* <!-- Password input --> */}


                <div className="form-outline ">
      {/* <label htmlFor="password">Password:</label> */}
      <div className="form-outline ">
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          className="form-control "
          placeholder="Enter Password"
          required
          autoComplete="current-password"
          style={{width:"100%"}}
          onChange={(e) => {
            setPassword(e.target.value); 
          }}
        />
        <i 
          type="button"
          onClick={togglePasswordVisibility}
          aria-label="Toggle password visibility"
          
        >
          < FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{position: "absolute", top:"22%",right:"6%"}}/>
        </i>
      </div>
    </div>
    <div className="text-center text-danger mb-4">
      {message}          
    </div>
                {/* <!-- 2 column grid layout for inline styling --> */}
                <div className="row mb-4">
                  <div className="col-6 d-flex justify-content-center">
                    {/* <!-- Checkbox --> */}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        // id="rememberMe"
                        // checked={rememberMe}
                        // onChange={
                        //     handleRememberMeChange
                        // }
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Remember me
                      </label>
                    </div>
                  </div>

                  <div className="col-6">
                    {/* <!-- Simple link --> */}
                    <a href="#!">Forgot password?</a>
                  </div>
                </div>

                {/* <!-- Submit button --> */}

                <button
                  type="button"
                  className="btn btn-danger  mb-2 w-90"
                  onClick={handleLogin}
                  style={{marginLeft:"12rem"}}
                >
                  Sign in
                </button>

                {/* <!-- Register buttons --> */}
                <div
                  className="text-center"
                  style={{ onhover: "curserpointer" }}
                >
                  <p>
                    Not a member?
                    </p>
                    <a
                    // type="button"
                      onClick={() => {
                        setModal(false);
                        setRegister(true);
                      }}
                    >
                      <div className="btn btn-primary  mb-2 w-90" style={{ backgroundColor:"" }}>
                        Register
                      </div>
                    </a>
                  
                  <p>or sign up with:</p>
                  {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-facebook-f"></i>
                                    </button> */}
              <Link to={googleLink}>
                  <button
                    type="button"
                    className="mr-2" 
                   style={{ border:"1px solid #f00", fontSize: "20px", width:"50px",height:"50px", borderRadius:"30px"}}
                  >
                    <i
                      className="fa fa-google"
                    ></i>
                  </button>
                </Link>
                <Link >
                  <button
                    type="button"
                    className="" 
                   style={{ border:"1px solid #00f", fontSize: "20px", width:"50px",height:"50px", borderRadius:"30px"}}
                  >
                    <i
                      className="text-primary fa fa-facebook"
                    ></i>
                  </button>
                </Link>
                  {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-twitter"></i>
                                    </button> */}

                  {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-linkedin"></i>
                                    </button> */}
                </div>
              </form>
            </ModalBody>
          </Modal>

          {/* register */}

          <Modal
            size="s"
            isOpen={register}
            toggle={() => setRegister(!register)}
          >
            <ModalHeader>
              Sign Up
              <button
                className="close-btn border-0"
                onClick={() => setRegister(false)}
              >
                <i className="fa fa-close"></i>
              </button>
            </ModalHeader>
            <ModalBody>
              <form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  {errorstate}
                </div>
                <div className="form-outline mb-2">
                  <input
                    type="text"
                    id="form2Example1"
                    className="form-control"
                    placeholder="Name"
                    onChange={(e) => {
                      setRegName(e.target.value);
                    }}
                  />
                  <div style={{ color: "red" }}>{name}</div>
                </div>

                {/* <div className="form-outline mb-2">
                                    <input type="text" id="form2Example1" className="form-control" placeholder="Last Name" />
                                    <label className="form-label" for="form2Example1"></label>
                                </div> */}

                <div className="form-outline mb-2">
                  <input
                    type="email"
                    id="form2Example1"
                    className="form-control"
                    placeholder="Enter Email id"
                    onChange={(e) => {
                      setRegEmail(e.target.value);
                    }}
                  />
                  <div style={{ color: "red" }}>{emailid}</div>
                </div>

                {/* <!-- Password input --> */}

               
                <div class="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example2"
                    className="form-control "
                    placeholder="Password"
                    onChange={(e) => {
                      setRegPassword(e.target.value);
                    }}
                  />
                  <div style={{ color: "red" }}>{passwordid}</div>
                </div>






                <div class="form-outline mb-4">
                  <input
                    type="password"
                    id="form2Example2"
                    className="form-control "
                    placeholder="Re-Enter Password"
                    onChange={(e) => {
                      setPasswordCofirmation(e.target.value);
                    }}
                  />
                  <div style={{ color: "red" }}>{repassword}</div> 
                </div>

                {/* <!-- 2 column grid layout for inline styling --> */}
                <div className="row mb-4">
                  <div className="col d-flex justify-content-center">
                    {/* <!-- Checkbox --> */}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1" style={{marginRight:"1rem"}}>
                        Remember me
                      </label>
                    </div>
                  </div>
                </div>

                {/* {isLoading ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : ( */}
                <button
                  type="button"
                  className="btn btn-danger btn mb-4 w-90"
                  onClick={handleReagister}
                  style={{ marginLeft:"11rem" }}
                  
                >
                  Register Now
                </button>
                 {/* )}  */}

                {/* <!-- Register buttons --> */}
                <div className="text-center">
                  {/* <p>
                                        Not a member?
                                        <a href="#!" type="submit">
                                            Register
                                        </a>
                                    </p> */}
                  <p>or sign up with:</p>
                  {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-facebook-f"></i>
                                    </button> */}

                <Link to={googleLink}>
                  <button
                    type="button"
                    className="mr-2" 
                   style={{ border:"1px solid #f00", fontSize: "20px", width:"50px",height:"50px", borderRadius:"30px"}}
                  >
                    <i
                      className="fa fa-google"
                    ></i>
                  </button>
                </Link>
                <Link >
                  <button
                    type="button"
                    className="" 
                   style={{ border:"1px solid #00f", fontSize: "20px", width:"50px",height:"50px", borderRadius:"30px"}}
                  >
                    <i
                      className="text-primary fa fa-facebook"
                    ></i>
                  </button>
                </Link>

                  {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-twitter"></i>
                                    </button> */}

                  {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-linkedin"></i>
                                    </button> */}
                </div>
              </form>
            </ModalBody>
          </Modal>
          {login_logout ? (
            <button className="logintbtnn" type="submit" onClick={logout}>
            <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
            </button>
          ) : (
            <button
              className="logintbtnn"
              type="submit"
              onClick={() => setModal(true)}
              // onChange={handleLogin}
              // onChange={login}
            >
              <i className="fa fa-sign-in" aria-hidden="true"></i> Login
            </button>
          )}

          {/* dropdown */}
          {localValue && (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-secondary dropdown-toggle"
                style={{
                  background: "transparent",
                  borderRadius: "80%",
                  border: "none"
                }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <a className="nav-link" href="">
                  <i
                    className="bi bi-person-fill"
                    style={{
                      fontSize: "25px",
                      marginLeft: "6px",
                    }}
                  ></i>
                </a>
              </button>
              <ul
                className="dropdown-menu"
                style={{ margin: " 16px 0px 0px -92px" }}
              >
                <li>
                  <a className="dropdown-item" href="" onClick={handleClickProfile}>
                    My Profile
                  </a>
                </li>
               
                
              </ul>
            </div>
          )}

          <div
            className="myp"
            style={{
              color: "white",
              marginTop: "0.1rem",
              fontSize: "25px",
            }}
          >
            <a className="nav-link" href="" onClick={handleClickProfile}>
              {/* <i className="bi bi-person-fill"></i> */}
            </a>
          </div>
        </div>
      </nav>
      {/* <div className='imageslider-container'> */}
      {/* <ImageSlid/>
      <Info/> */}
      {/* </div> */}
      {/* <Explore/> */}
    </>
  );
}

export default Navnbar;
