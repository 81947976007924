import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../Service/ApiMethod";
import {
  BOOKTICKET,
  GETTICKETDETAILS,
  USERPROFILE,
} from "../../Service/service";
import "./BookNow.css";
import Form from "react-bootstrap/Form";
import Navnbar from "../Navnbar";
import Toggle from "react-toggle";
import { ToggleButton } from "react-bootstrap";
import ReactSwitch from "react-switch";

const BookNow = () => {
  const [show, setShow] = useState(false);
  const [tipDetailShow, setTipDetailShow] = useState(false);
  const [shows, setShows] = useState(true);
  const [empty, setEmpty] = useState(false);

  const [ticketDate, setTicketDate] = useState("");

  // const param3 = useParams();

  // const [dateId, setDateId] = useState();
  // const date1 = new Date();
  // const formattedDate = date1.getDate();
  // console.log("formatdate", formattedDate);
  // function down() {
  //   setShow(true);
  //   setShows(false);
  // }

  // function up() {
  //   setShow(false);
  //   setShows(true);
  // }
  // const getDetail = () => {
  //   getData(GETTICKETDETAILS + param3.id)
  //     .then((response) => response.json())
  //     .then((res) => {
  //       setTicketData(res.data);
  //       console.log("ddddddddd", res.data);
  //     })
  //     .catch((err) => {
  //       console.log("Error in categories from Post Form", err);
  //       console.log(" code Error", err);
  //     });
  // };
  // console.log(typeof resData);
  // useEffect(() => {
  //   getDetail();
  // }, []);
  function down() {
    setShow(true);
    setShows(false);
  }

  function up() {
    setShow(false);
    setShows(true);
  }
  const [num, setNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const increment = (e) => {
    e.preventDefault();
    setNum(num + 1);
    console.log("Added", num);
  };
  const decrement = (e) => {
    e.preventDefault();
    if (num > 0) setNum(num - 1);
    else setNum(0);
  };
  const reset = (e) => {
    e.preventDefault();
    setNum(0);
  };

  // const ref = useRef(null);
  const navigate = useNavigate();
  const navigate2 = useNavigate();
  // const handleClick = () => {
  //     ref.current?.scrollIntoView({ behavior: "smooth" });
  //     navigate("");
  // };
  const param3 = useParams();
  const [ticketData, setTicketData] = useState();
  // const navigateConfirmation = useNavigate();
  // const API_DATE_STRING = "2021-06-12T11:00:00.000Z";
  const date1 = new Date();
  const formattedDate = date1.getDate();
  console.log("formatdate", formattedDate);

  const getDetail = () => {
    getData(GETTICKETDETAILS + param3.id)
      .then((response) => response.json())
      .then((res) => {
        setTicketData(res.data);
        console.log("ddddddddd", res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  console.log(typeof resData);
  useEffect(() => {
    getDetail();
  }, []);

  const [dateId, setDateId] = useState();
  const [ticketId, setTicketId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const navigateToConfirmation = useNavigate();

  //error validation
  const [showstate, setShowState] = useState(false);
  const [errorstate, setErrorState] = useState("");
  const [namestate, setNameState] = useState();
  const [emailid, setEmailId] = useState();
  const [mobileid, setMobileId] = useState();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [DateError, setDateError] = useState();
  
  const [inputValue, setInputValue] = useState("");
  const emailRegex = /^\S+@\S+\.\S+$/;

  const handleClick = (id) => {
    setIsLoading(true);
    let dataObj = {
      date:dateId,
      ticket_id: parseInt(param3.id),
      name: (!empty) ? name: (name || profileData?.data?.name),
      email: (!empty) ? email: (email || profileData?.data?.email),
      // email: email || profileData?.data?.email, 
      mobile: mobile, 
    };

    // console.log("dataobj", dataObj);
    postData(dataObj, BOOKTICKET)
      .then((response) => response.json())
      .then((data) => {
        // console.log("iiiiii", data);
        // console.log("dsdasda", data.data?.order_data);
        try {
          // console.log(data);
          const status = data?.status;
          if (status === 400) {
            setErrorState(data.message);
          } else if (status === 200) {
            navigate(`/bookingsummary`, {
              state: {
                userData: data.data?.order_data,
              },
            });
          } else if (data.status === 401) {
            setNameState(data.errors.name);
            setEmailId(data.errors.email);
            setMobileId(data.errors.mobile);
            setDateError(data.errors.date);
          }
        } catch (error) {
          console.log("---", error);
        } finally {
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // useEffect(() => {
  //     handleClick();
  // }, []);
  useEffect(() => {
    setIsEmailValid(emailRegex.test(email));
  }, [email]);
  function handleKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // Regular expression to allow only numbers

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  }

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression to validate email addresses
    return regex.test(email);
  }
  const [checked, setChecked] = useState(true);
  const [profileData, setProfileData] = useState();

  const handleChange = () => {
    setChecked(!checked);
    setEmpty(!empty)
    console.log("state", checked);
    if (checked) {
      getData(USERPROFILE)
        .then((response) => response.json())
        .then((res) => {
          setProfileData(res);
          // setProfileData(res.name)
          console.log("---profile", res);
        })
        .catch((err) => {
          console.log("Error in categories from Post Form", err);
          console.log(" code Error", err);
        });
    }
  };

  useEffect(() => {
    handleChange();
  }, []);
  const changeCSS = (data) => {
    console.log(data);
    setTicketDate(data);
    setTipDetailShow(true);
  };
  return (
    <React.Fragment>
      <Navnbar />
      <body>
    <div className="d" style={{position: "relative"}}> 
        <h3 id="heading" style={{textAlign: "center",marginTop:"2rem",marginBottom:"2rem"}}>
        TICKET DETAILS
        </h3>
    </div>
    <div>
        <span >
               .
        </span> 
    </div>
</body>
      {/* <h2 className="dform">Ticket Details</h2> */}
      <div className="text-center text-danger mb-2">
                                      {DateError} 
                                      </div>
      <div className="main-container-accordian">
        {ticketData &&
          ticketData.ticket_details.map((ticket) => (
            <div className="main-container-header">
              <div className="main-container-header-content">
                {" "}
                {ticket.heading}
              </div>
              <div className="header-content"> {ticket.description}</div>
              {/* <div className="main-container-header-content">
                Original price: ₹ {ticket.original_price}
              </div>
              <div className="main-container-header-content">
                Discount: ₹ {ticket.discount}
              </div>
              <div className="main-container-header-content">
                Sale price: ₹ {ticket.sale_price}
              </div> */}
              {shows ? (
                <div
                  className="main-container-header-content"
                  onClick={down}
                >
                  <i
                    class="fa fa-angle-double-down"
                    aria-hidden="true"
                    style={{ cursor: "pointer", fontSize: "22px" }}
                  ></i>
                </div>
              ) : (
                <div className="main-container-header-content" 
                onClick={up}
                >
                  <i
                    class="fa fa-angle-double-up"
                    aria-hidden="true"
                    style={{ cursor: "pointer", fontSize: "22px" }}
                  ></i>
                </div>
              )}
            </div>
          ))}

        {show ? (
          <div className="main-container-body">
            <div className="main-container-body-carousel">
              {tipDetailShow ? (
                <div className="detailShow prc">
                  {ticketData && ticketData.ticket_details.map((ticket) => (
                    <span class="tooltip-content clearfix" style={{ borderRadius: "20px" }}>
                      <span class="tooltip-text">
                        {" "}
                        Original Price: &nbsp; &nbsp;₹{" "}
                        <span style={{ textDecoration: "line-through", }}>
                          {" "}
                          {ticket.original_price}
                        </span>
                        <br />
                        <span>
                          Sale Price: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₹{" "}
                          {ticket.sale_price}
                          <br />
                          Discount: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          {ticket.discount} Off
                        </span>
                      </span>
                    </span>
                  ))}
                </div>
              ) : (
                <div className="detailShow slct">
                  <i class="fa fa-angle-double-down" aria-hidden="true" style={{ fontSize: "55px", margin: "20px 0px 0px 0px" }}></i>
                   &nbsp;Select date
                  <i class="fa fa-angle-double-down" aria-hidden="true" style={{ fontSize: "55px", margin: "20px 0px 0px 10px" }}></i>{" "}
                </div>
              )}

              {ticketData &&
                ticketData.availableDates.map((dateData) => (
                  <div className="main-container-body-carousel-inner">
                    <div>
                      <span class="mytooltip test tooltip-effect-5">
                        <span class="tooltip-item">
                          <span
                            className={
                              ticketDate === dateData
                                ? "calender-focus calendar"
                                : "calendar"
                            }
                            onClick={() => {
                              changeCSS(dateData);
                            }}
                          >
                            <span className="calendar-week">
                              {moment(dateData.date).format("ddd")}
                            </span>
                            <br />
                            <span
                              className="calendar-day"
                              onClick={(e) => {setDateId(dateData.date)
                          
                              }}
                            >
                              {moment(dateData.date).format("Do MMM")}
                            </span>
                          </span>
                        </span>
                        {ticketData &&
                          ticketData.availableDates.map((ticket) => (
                            <span
                              class="tooltip-content clearfix"
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <span class="tooltip-text">
                                Ticket Availables : {ticket.available_capacity}{" "}
                                <br />
                                <br />
                                <span>
                                  {" "}
                                  Current Price -{" "}
                                  <span style={{}}>
                                    {" "}
                                    {
                                    Math.floor( ticket.current_price)}
                                  </span>
                                </span>
                              </span>
                            </span>
                          ))}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>

      {/* Detail form start */}

      
      <body>
    <div className="d" style={{position: "relative"}}> 
        <h3 id="heading" style={{textAlign: "center",marginTop:"2rem",marginBottom:"2rem"}}>
        YOUR DETAILS
        </h3>
    </div>
    <div>
        <span >
               .
        </span> 
    </div>
</body>
      {/* <h2 className="dform1">Details Form</h2> */}

                                      
      <div
        className="card input-field-container">
        <div className="card-body" style={{ marginTop: "2rem" }}>
          
      <span>
         <span className="togglerS"> Self : </span>           
           {/*  <span class="form-check form-switch" >
           <input class="form-check-input" onChange={handleChange} type="checkbox" id="flexSwitchCheckDefault"/>
          </span> */}
          <ReactSwitch checked={checked} uncheckedIcon={false} checkedIcon={false} onChange={handleChange} /> 
            <span className="togglerS">: For Friends</span>  
        </span> 

        {/* <input type="checkbox" checked toggle="toggle" data-on="Ready" data-off="Not Ready" data-onstyle="success" data-offstyle="danger"  onChange={handleChange}/> */}
          <Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                Color: "red",
              }}
            >
              {errorstate}
            </div>
            {/* {profileData  ? ( */}
            <div className="pt-3">
              <div className="main-text-field">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    {/* Name */}
                  </label>
                  {empty && 
                  <input
                  type="Text"
                  value={profileData?.data?.name}
                  checked={checked}
                  class="form-control"
                  placeholder="Enter you name"
                  id="exampleFormControlInput1"
                  // onChange={(e) => {
                  //   setName(e?.target?.value);
                  // }}
                />
                  }
                  {!empty && 
                  <input
                  type="Text"
                  // value={profileData?.data?.name}
                  checked={checked}
                  class="form-control"
                  placeholder="Enter your friend name"
                  id="exampleFormControlInput1"
                  onChange={(e) => {
                    setName(e?.target?.value);
                  }}
                />
                  }
                
                  <div style={{ color: "red" }}>{namestate}</div>
                </div>
              </div>

              <div className="main-text-field">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    {/* Email address */}
                  </label>
                  {empty &&
                  <input
                  type="email"
               
                  value={profileData?.data.email}
                  class="form-control"
                  required
                  style={
                    isValidEmail(inputValue) ? {} : { borderColor: "none" }
                  }
                  autoComplete="off"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  // onChange={(e) => {
                  //   setEmail(e.target.value);
                  //   setInputValue(e.target.value);
                  // }}
                />
                  }
                  {!empty &&
                  <input
                  type="email"
               
                  class="form-control"
                  required
                  style={
                    isValidEmail(inputValue) ? {} : { borderColor: "none" }
                  }
                  autoComplete="off"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setInputValue(e.target.value);
                  }}
                />
                  }
                  <div style={{ color: "red" }}>{emailid}</div>
                </div>
              </div>

              <div className="main-text-field">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    {/* Mobile No. */}
                  </label>
                  <input
                    type="tel"
                    maxLength={10}
                    onKeyPress={handleKeyPress}
                    required
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter you mobile no."
                    onChange={(e) => setMobile(e?.target?.value)}
                  />
                  <div style={{ color: "red" }}>{mobileid}</div>
                </div>
              </div>
            </div>
            {/* ) : null}   */}
            {isLoading ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <button className="btn-bookbtn" type="submit"
                onClick={(e) => { handleClick(e.preventDefault()) }}>Book Now
              </button>
            )}
          </Form>
        </div>
      </div>
    </React.Fragment>
  ); 
}; 

export default BookNow;





  {/* <span class="form-check form-switch">
           <input class="form-check-input" onChange={handleChange} type="checkbox" id="flexSwitchCheckDefault"/>
          </span> */}