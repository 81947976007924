import axios from "axios";
import moment from "moment";
import React, { useEffect, useState,useRef } from "react";
import { getData } from "../../Service/ApiMethod";
import { GENERATETICKET, MYORDERS } from "../../Service/service";
import Navnbar from "../Navnbar";
import "./myorder.css";
import {useNavigate} from 'react-router-dom'

const MyOrder = () => {
  const [resData, setResData] = useState();
  // const [showbutton, setShowbutton] = useState()
  const [pdf, setPdf] = useState();
  const navigate = useNavigate()
  const ref = useRef(null);
  const navigateToMain = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
    navigate("/explore")
  };

  const handleClick = () => {
    getData(MYORDERS)
      .then((response) => response.json())
      .then((res) => {
        setResData(res.data);
        console.log("vddsgfgsdfgsdfgs", res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  const handleGenrate = (id) => {
    getData(GENERATETICKET + id)
      .then((response) => response.json())
      .then((res) => {

        // const url = [res.pdf];
        // const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = res.pdf;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
        // console.log("PDF URL:", url);
        // console.log("->>>>>>>>>>>>>", res);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  useEffect(() => {
    handleClick();
    // handleGenrate()
  }, []);

  return (
    <>
      <Navnbar />
      <body>
    <div className="d" style={{position: "relative"}}> 
        <h3 id="heading" style={{textAlign: "center",marginTop:"2rem",marginBottom:"2rem"}}>
        ORDERS
        </h3>
    </div>
    <div>
        <span >
               .
        </span> 
    </div>
</body>
      {resData != undefined ? resData.map((item, index) => {
        return (
          <div className="d-flex justify-content-center">
            <div className=" neeraj1" style={{
              borderRadius: "20px", position: "relative",
              marginTop: "2rem",width:"75%", border:"1px solid #dfdfdf", padding:"10px", boxShadow:"0px 0px 20px 5px #dfdfdf"
            }}>
              <div className="row">
                <div className="col-2">
                  <div className="d-inline-block align-middle">
                    <img
                      src="https://www.shutterstock.com/image-photo/amusement-park-rides-260nw-15798883.jpg"
                      alt="useImage"
                      style={{ height: "105px" }}
                    />
                  </div>
                </div>
                <div className="col-8">
                  <tr className="border-bottom">
                    <th className="ps-0 ">{item.heading}</th>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <th className="ps-0 ">
                      {item.status == 1 ? (
                        <label class="label label-success">Success</label>
                      ) : (
                        <label class="label label-danger">cancelled</label>
                      )}
                    </th>
                    <th className="ps-5 text-end ">Booked-For &nbsp;</th>
                    <th className="ps-0 text-end sub-title">
                      {moment(item.date_for).format("DD-MMM-YYYY")}
                    </th>
                  </tr>
                  <br />
                  <tr className="border-bottom">
                    <th className="ps-0 ">{item.name}</th>&nbsp;
                    <th className="pe-0 text-end sub-title">
                      &nbsp;{item.mobile}
                    </th>
                    <th className="ps-5 text-end ">Order-ID &nbsp;</th>
                    <th className="pe-0 text-end sub-title">
                      &nbsp;{item.razorpay_order_id}
                    </th>
                  </tr>
                  {item.status == 1 ? (
                    <>
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Download Ticket"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "-7rem",
                          bottom: "1rem",
                        }}
                      >
                        <i
                          className="bi bi-download"
                          onClick={() => handleGenrate(item?.id)}
                        ></i>
                      </span>
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Invoice File"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "-5rem",
                          bottom: "1rem",
                        }}
                      >
                        {/* <i class="bi bi-card-text"></i> */}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
          </div>
          </div>
        );
      })
        : 
        <div className="row d-flex justify-content-center mt-5">
        <div className="col-3">
          <p>  <b> No Orders Found ! </b> 
          <button className="explore-btn  " type="submit"  onClick={navigateToMain}>Explore Now !</button>
          </p>
        </div>
      </div>
      
        } 
    </>
  );
};

export default MyOrder;
