import React from "react";
import "./ImageSlider.css";
// import leftArrow from "./icons/left-arrow.svg";
// import rightArrow from "./icons/right-arrow.svg";
export default function BtnSlider({ direction, moveSlide }) {
  // console.log(direction, moveSlide);
  return (
    
    <button
      onClick={moveSlide}
      id={'slideBtn'}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"} style={{ background:" #092d7550", boxShadow:"0px 0px 6px 2px  #ffff"}}
    >
     {direction === "next" ? <i class="fa fa-chevron-right text-light" aria-hidden="true"></i> : <i class="fa fa-chevron-left text-light" aria-hidden="true"></i>}
    </button>
  );
}