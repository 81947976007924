import React, { useEffect, useState } from "react";
import Navenbar from "../Navnbar";
import "./conformation.css";
import tcc from "./TicketsImg/tcc1.png";
import pur2 from "./TicketsImg/pur2.png";
import qr from "./TicketsImg/qr.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { getData } from "../../Service/ApiMethod";
import { baseUrl, BOOKINGSUMMARY, PAYMENTVERIFY } from "../../Service/service";
import axios from "axios";
import moment from "moment";
const Confirmation = () => {
  const location = useLocation();
  const paymentData = location?.state?.data
  console.log("SSSSSSSSSS", paymentData)
  const imagePathURL = baseUrl+`/backend/tickets/`; //devServer
  // const imagePathURL =baseUrl+ `/backend/tickets/`;
  return (
    <>
      <Navenbar />
      <div className="prs_title_main_sec_wrapper">
        <div className="st_bcc_main_wrapper">
          <div className="container">
            {paymentData != undefined ? paymentData.map((item, index) => {
              const combineImageUrl = imagePathURL + item?.image;
              console.log("combineImageUrl", combineImageUrl);
              return (
                <div className="row">
                  <div className="col-md-12">
                    <div className="st_bcc_ticket_boxes_wrapper float_left">
                      <div className="st_bcc_tecket_top_hesder float_left">
                        <p>Your Booking is Confirmed!</p>{" "}
                        <span>Booking ID {item.razorpay_order_id} </span>
                      </div>
                      <div className="st_bcc_tecket_bottom_hesder float_left">
                        <div className="st_bcc_tecket_bottom_left_wrapper">
                          <div className="st_bcc_tecket_bottom_inner_left">
                            <div className="st_bcc_teckt_bot_inner_img">
                              <img src={combineImageUrl} alt="img" style={{ width: "10rem" }} />
                            </div>
                            <div className="st_bcc_teckt_bot_inner_img_cont">
                              <h4>{item.heading}</h4>
                              <h5><b>{item.mobile}</b></h5>
                              <h3><b>{moment(item.date_for).format("DD-MMM-YYYY")}</b></h3>
                              <h6><b>{item.name}</b><br /><b>{item.email}</b></h6>
                            </div>
                            <div className="st_purchase_img">
                              <img src={pur2} alt="img" />
                            </div>
                          </div>
                          <div className="st_bcc_tecket_bottom_inner_right">
                            {" "}
                            <i className="fas fa-chair"></i>
                            <h3>1 TICKET</h3>
                          </div>
                        </div>
                        <div className="st_bcc_tecket_bottom_right_wrapper">
                          <img src={qr} alt="img" />
                          <h4>Booking ID<br />{item.razorpay_order_id}</h4>
                        </div>
                        <div className="st_bcc_tecket_bottom_left_price_wrapper"><br />
                          <h4>Total Amount</h4>
                          <h5>₹{item.sale_price}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)})
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default Confirmation;