import React, { useState, useEffect } from "react";
import Topbar from "../AdminNavbar/Topbar";
import { Link } from "react-router-dom";
import SideNavbar from "../AdminNavbar/SideNavbar";
import ListOfRides from "./ListOfRides";
import { useParams } from "react-router-dom";
import { getData, postData } from "../../Service/ApiMethod";
import { ADMINASSIGNRIDESTICKET, ADMINGETTICKET } from "../../Service/service";

const AllTicketList = () => {
  const [userData, setUserData] = useState();
  const [original_price, setOriginalPrice] = useState();
  const [sale_price, setSalePrice] = useState();

  const param = useParams();

  const getDetail = () => {
    // console.log("<<<<", ADMINGETTICKET);
    getData(ADMINGETTICKET + param.userid)
      .then((response) => response.json())
      .then((res) => {
        setUserData(res);
        setOriginalPrice(res?.data?.original_price);
        setSalePrice(userData?.data?.sale_price);
        console.log("---", res);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);
  const setTotalCost = (total_cost) => {
    setOriginalPrice(parseInt(total_cost));
  };

  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="page-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card o-visible">
                            <div className="card-header">
                              <h5>Ticket Details</h5>
                              <div className="card-header-right">
                                <ul className="list-unstyled card-option">
                                  <li>
                                    <i className="fa fa fa-wrench open-card-option"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-window-maximize full-card"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-minus minimize-card"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-refresh reload-card"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-trash close-card"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {userData != undefined ? (
                              <div className="card-block">
                                <div className="row">
                                  <div className="col-2 mx-3 pt-2">
                                    {userData.data.heading}
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group form-default form-inline">
                                      <span>Rs.</span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={original_price}
                                        onChange={(e) =>
                                          setOriginalPrice(e.target.value)
                                        }
                                      />
                                      (OriginalPrice)
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group form-default form-inline">
                                      <span>Rs.</span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sale_price}
                                        onChange={(e) =>
                                          setSalePrice(e.target.value)
                                        }
                                      />
                                      (SalePrice)
                                    </div>
                                  </div>{" "}
                                </div>
                                <br />
                                <div className="col-8">
                                  {userData.data.description}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ListOfRides setTotalCost={setTotalCost} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTicketList;
