import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';


const Protect = (props) => {
    const { Component } = props;
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('fun_park_token')
        // if (userData == undefined || userData == null) {
        //     navigate('/')
        // }
        if (!userData) {
            navigate('/admin-login')
        }
    })
    return (
        <div>
            <Component />
        </div>
    )
}


export default Protect