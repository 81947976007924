import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getData } from "../../Service/ApiMethod";
import { ALLORDERS, DATEFROMTO } from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";

function OrderList() {
  const [allOrders, setAllOrders] = useState();
  const [date_from, setDateFrom] = useState();
  const [date_to, setDateTo] = useState();
  const [filterdata, setFilterData] = useState([]);

  //error validation
  const [errorstate, setErrorState] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();


  const getDetail = () => {
    getData(ALLORDERS)
      .then((response) => response.json())
      .then((res) => {
        setAllOrders(res.data);
        // console.log("11111111",res.data);

      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };


  const getDate = () => {
    getData(DATEFROMTO + `?date_from=${date_from}&date_to=${date_to}`)
      .then((response) => response.json())
      .then((res) => {
        // console.log("asdf", res);
        try {
          // console.log("hi tarun",data);
          const status = res.status;
          // console.log(status)
          
          if (status === 200) {
            setErrorState(
              setAllOrders(res.data),
              setFilterData(res.data),
              setDateFrom(res.data),
              setDateTo(res.data)
            );
            // window.location.reload()

          } else if (status === 401) {
            
            setFrom(res.error.date_from);
            setTo(res.error.date_to);

          }
        } catch (error) {
          console.log("---", error);
        }
      })
    const searchdata1 = date_from.filter((item) => item.date_for.toLowerCase().includes(date_from));
    setDateFrom(searchdata1)
    const searchdata = date_to.filter((item) => item.date_for.toLowerCase().includes(date_to));
    setDateTo(searchdata)
    // console.log(">>>>>>>>>",searchdata);


    // }else {

    //   getDetail();
    // }

  }
  // console.log(typeof resData);
  useEffect(() => {
    getDetail();
  }, []);
  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-header" style={{ paddingBottom: "0px" }}>

                                <div className="row" style={{ paddingBottom: "0px" }}>
                                  <div className="col-4"><h5>Order Details</h5></div>
                                  {/* <div style={{ display: "flex", justifyContent: "center", color: "red", }}>{errorstate}
                                  </div> */}
                                  <div className="col-3 row" style={{ bottom: "-6px", right: "-20rem" }}>
                                    <div className="col-5" style={{ right: "0rem",top:"-5px" }}><h6>From :</h6></div>
                                    <div className="col-6" style={{ bottom: "19px", right: "55px" }}>
                                      <form className="form-material">
                                        <div className="form-group form-primary">
                                          <input type="date" className="form-control" value={date_from} onChange={(e) => { setDateFrom(e.target.value) }} style={{ width: "114px" }}/>
                                          <span class="form-bar"></span>
                                        </div>
                                      </form>
                                    </div>
                                    {/* <div className="col-6" style={{ color: "red", right: "-3rem", top: "-38px" }}>{from}</div> */}
                                  </div>
                                  <div className="col-3 row" style={{ bottom: "-1px", right: "-15rem" }}>
                                    <div className="col-3" style={{ right: "-2rem" }}><h6>to :</h6></div>
                                    <div className="col-6" style={{ bottom: "14px" }}>
                                      <form className="form-material">
                                        <div className="form-group form-primary">
                                          <input type="date" className="form-control" value={date_to} onChange={(e) => { setDateTo(e.target.value) }} style={{ width: "114px" }} />
                                          <span class="form-bar"></span>
                                        </div>
                                      </form>
                                    </div>
                                    {/* <div className="col-6" style={{ color: "red", right: "-4rem", top: "-36px" }}>{to}</div> */}
                                  </div>
                                  <div className="col-2" style={{ bottom: "7px", right: "-10rem" }}>
                                    <button type="button" className="btn btn-primary" onClick={() => { getDate() }}>Submit</button>
                                  </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", color: "red",bottom:"2px" }}>{from}
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", color: "red", bottom: "2px" }}>{to}
                                </div>
                              </div>
                              
                              <div className="card-block table-border-style">
                                <div className="table-responsive">
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th>Sr. No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone. No.</th>
                                        <th>TicketId/Date</th>
                                        <th>OrderID</th>
                                        <th>OrderDate</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    {allOrders !== undefined ? allOrders.map((item, index) => {
                                      return (
                                        <tbody>
                                          <tr>
                                            <th scope="row">{item?.id}</th>
                                            <td>{item?.name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.mobile}</td>
                                            <td>
                                              {item?.ticket_for}
                                              &nbsp;/&nbsp;{item?.date_for}
                                            </td>
                                            <td>
                                              {item?.razorpay_order_id}
                                            </td>
                                            <td>{item?.order_date}</td>
                                            <td>
                                              {item.status == 0 ? (
                                                <label class="label label-danger">
                                                  cancelled
                                                </label>
                                              ) : (
                                                <label class="label label-success">
                                                  Success
                                                </label>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      );
                                    })
                                      : null}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderList;
