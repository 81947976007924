import React from "react";
import Protected from "../components/Protected/Protected";
import Protect from "../components/Dashboard/Protect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Explore from "../components/Explore";
import GoogleCallback from "../components/google/GoogleCallback";
import Home from "../components/Home";
import Dashboard from "../components/Dashboard/Dashboard";
import LoginPage from "../components/Dashboard/Login";
import AllRidesPage from "../components/Dashboard/AllRidesPage";
import AllTickets from "../components/Dashboard/AllTickets";
import AllTicketsList from "../components/Dashboard/AllTicketList";
import ViewRide from "../components/TicketDetail/ViewRide";
import BookNow from "../components/BookNow/BookNow"
import AddTicketsPage from "../components/Dashboard/AddTicketsPage";
import AddRidesPage from "../components/Dashboard/AddRidesPage";
import ManageInventory from "../components/Dashboard/ManageInventory";
import ManageTickets from "../components/Dashboard/ManageTickets";
import OrderList from "../components/Dashboard/OrderList";
import ReportPageList from "../components/Dashboard/ReportPageList";
import NotifyProducts from "../components/Dashboard/NotifyProducts";
import MyProfile from "../components/MyProfile/MyProfile";
import MyOrder from "../components/MyProfile/MyOrder";
import SubAdminLogin from "../components/SubAdmin/SubAdminLogin";
import CreateTicket from "../components/SubAdmin/CreateTicket";
import Users from "../components/Dashboard/Users";
import Confirmation from "../components/BookNow/Confirmation";
import BookingSummary from "../components/BookNow/BookingSummary";
import AdminProfileUpdate from "../components/Dashboard/AdminProfileUpdate";
import SubAdminDeshboard from "../components/SubAdmin/SubAdminDeshboard";
import AlredyMangedInventory from "../components/Dashboard/AlredyMangedInventory";

const RoutesAll = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"/explore"} element={<Explore />} />
                <Route path={"/google/callback"} element={<GoogleCallback />} />


                <Route
                    path={"/admin-dashboard"}
                    element={<Protect Component={Dashboard} />}
                />
            
                <Route element={<LoginPage />} path={"/admin-login"} />
                <Route element={<SubAdminLogin />} path={"/subadmin-login"} />

                <Route element={<AllRidesPage />} path={"/all-rides"} />
                <Route element={<AllTickets />} path={"/all-tickets"} />
                <Route
                    element={<AllTicketsList />}
                    path={"/all-tickets/ticketlist/:userid"}
                />
                <Route
                    path={"/TicketDetail/ViewRide/:id"}
                    element={<ViewRide />}
                />
                {/* <Route path={"/booknow/:id"} element={<BookNow />} /> */}
                {/* <Route
                    path="/booknow/:id"
                    element={<Protected Component={BookNow} />}
                /> */}
                <Route path={"/booknow/:id"} element={<BookNow />} />
                {/* <Route
                    path="/booknow/:id"
                    element={<Protected Component={BookNow} />}
                /> */}
                {/* <Route element={<MyOrder />} path={"/myorder"} /> */}
                <Route
                    path="/MyOrder"
                    element={<Protected Component={MyOrder} />}
                />
                {/* <Route element={<AddTicketsPage />} path={"/add-tickets"} /> */}
                <Route
                    path={"/add-tickets"}
                    element={<Protect Component={AddTicketsPage} />}
                />
                {/* <Route element={<AddRidesPage />} path={"/add-rides"} /> */}
                <Route
                    path={"/add-rides"}
                    element={<Protect Component={AddRidesPage} />}
                />
                {/* <Route element={<ManageInventory />} path={"/manage-inventory"} /> */}
                <Route
                    path={"/manage-inventory"}
                    element={<Protect Component={ManageInventory} />}
                />
                 <Route
                    path={"/alredy-manage-inventory"}
                    element={<Protect Component={AlredyMangedInventory} />}
                />
                {/* <Route element={<ManageTickets />} path={"/manage-tickets"} /> */}
                <Route
                    path={"/manage-tickets"}
                    element={<Protect Component={ManageTickets} />}
                />
                {/* <Route element={<OrderList />} path={"/order-list"} /> */}
                <Route
                    path={"/order-list"}
                    element={<Protect Component={OrderList} />}
                />
                {/* <Route element={<ReportPageList />} path={"/report-list"} /> */}
                <Route
                    path={"/report-list"}
                    element={<Protect Component={ReportPageList} />}
                />
                <Route
                    path={"/report-list"}
                    element={<Protect Component={ReportPageList} />}
                />
                <Route
                    path={"/notify-products"}
                    element={<Protect Component={NotifyProducts} />}
                />


                {/* <Route path={"/myprofile"} element={<MyProfile />}  /> */}
                <Route
                    path="/myprofile"
                    element={<Protected Component={MyProfile} />}
                />
                <Route element={<SubAdminLogin />} path={"/sub-admin-login"} />
                <Route element={<CreateTicket />} path={"/create-ticket"} />
                <Route element={<Users />} path={"/users"} />
                <Route element={<Confirmation />} path={"/confirmation"} />
                <Route element={<BookingSummary />} path={"/bookingsummary"} />
                <Route element={<AdminProfileUpdate />} path={"/admin-profile-update"} />
                <Route element={<SubAdminDeshboard />} path={"/sub-admin-dashboard"} />
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesAll;
