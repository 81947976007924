import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../Service/ApiMethod";
import { NOTIFICATION, NOTIFICATIONUPDATE } from "../../Service/service";
import man from "../Imgs/faq_man.png"
function Topbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notification, setNotification] = useState();
  const navigate = useNavigate();
  const [hasData, setHasData] = useState(true);

  const getDetail = () => {
    getData(NOTIFICATION)
      .then((response) => response.json())
      .then((res) => {
        setNotification(res.data);
        if (res.data.length === 0) {
          setHasData(false);
        }
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  }
  const handleClick = (razorpay_order_id) => {

    let dataObj = {
      id: razorpay_order_id,
    };
    postData(dataObj, NOTIFICATIONUPDATE)
      .then((response) => response.json())
      .then((res) => {
        navigate("/order-list");
        window.location.reload()
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  useEffect(() => {
    if (
      localStorage.getItem("fun_park_token") &&
      localStorage.getItem("email")
    ) {
      setIsLoggedIn(true);
    }
    getDetail();
  }, []);
  //     const logout = () =>{
  //         if(localStorage.getItem("email")){

  //     }

  // }
  const isSubAdmin = localStorage.getItem("isSubAdmin");
  const logout = () => {
    // console.log("--------",isSubAdmin);
    localStorage.removeItem("fun_park_token");
    localStorage.removeItem("email");
    window.location.href = "/admin-login";
  };


  return (
    <nav className="navbar header-navbar pcoded-header">
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <Link className="mobile-menu waves-effect waves-light" id="mobile-collapse" to={""}>
            <i className="ti-menu"></i>
          </Link>
          {/* <div className="mobile-search waves-effect waves-light">
            <div className="header-search">
              <div className="main-search morphsearch-search">
                <div className="input-group">
                  <span className="input-group-addon search-close">
                    <i className="ti-close"></i>
                  </span>
                  <input type="text" className="form-control" placeholder="Enter Keyword" />
                  <span className="input-group-addon search-btn">
                    <i className="ti-search"></i>
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          <Link to={""}>
            <img className="img-80 img-radius p-3" src="../../assets/images/logo.png" alt="User-Profile-Image"
              style={{ width: "180px" }} />
          </Link>
          {/* <Link className="mobile-options waves-effect waves-light">
                        <i className="ti-more"></i>
                    </Link> */}
        </div>

        <div className="navbar-container container-fluid">
          <ul className="nav-left">
            <li>
              <div className="sidebar_toggle">
                <Link to={""}>
                  <i className="ti-menu"></i>
                </Link>
              </div>
            </li>
            {/* <li className="header-search">
              <div className="main-search morphsearch-search">
                <div className="input-group">
                  <span className="input-group-addon search-close">
                    <i className="ti-close"></i>
                  </span>
                  <input type="text" className="form-control" />
                  <span className="input-group-addon search-btn">
                    <i className="ti-search"></i>
                  </span>
                </div>
              </div>
            </li> */}
            {/* <li>
                            <Link to={""} className="waves-effect waves-light">
                                <i className="ti-fullscreen"></i>
                            </Link>
                        </li> */}
          </ul>

          <ul className="nav-right">
            <li className="header-notification">
              <Link to={""} className="waves-effect waves-light">
                <i className="ti-bell"></i>
                <span className="badge bg-c-red"></span>
              </Link>

              <ul className="show-notification" style={{ overflowY: "auto", height: "300px" }}>
                <li>
                  <h6>Notifications</h6><label className="label label-danger">New</label>
                </li>
                {hasData && notification != undefined ? notification.map((item, index) => {
                  return (
                    <li className="waves-effect waves-light" onClick={() => handleClick(item.razorpay_order_id)}>
                      <div className="media">
                        <img src={man} width="100%" alt="" data-dz-thumbnail="data-dz-thumbnail"style={{ borderRadius: "50%" }}/>
                        <div className="media-body">
                          <h5 className="notification-user">{item?.name}</h5>
                          <p className="notification-msg">New Order With ID : {item?.razorpay_order_id}</p>
                          <span className="notification-time">30 minutes ago</span>
                        </div>
                      </div>
                    </li>
                  )}) : <p style={{ textAlign: "center", fontSize: "20px" }}>Everything Up To Date</p>}
              </ul>
            </li>

            <li className="user-profile header-notification">
              <Link to={""} className="waves-effect waves-light">
                <img
                  src={man}
                  width="100%"
                  alt=""
                  data-dz-thumbnail="data-dz-thumbnail"
                  style={{ borderRadius: "50%" }}
                />
                <span>John Doe</span>
                <i className="ti-angle-down"></i>
              </Link>
              <ul className="show-notification profile-notification">
                {/* <li className="waves-effect waves-light">
                                    <Link to={""}>
                                        <i className="ti-settings"></i> Settings
                                    </Link>
                                </li> */}
                <li className="waves-effect waves-light">
                  <a href={"/admin-profile-update"}>
                    <i className="ti-user"></i> Profile
                  </a>
                </li>
                {/* <li className="waves-effect waves-light">
                                    <Link to={""}>
                                        <i className="ti-email"></i> My Messages
                                    </Link>
                                </li> */}
                {/* <li className="waves-effect waves-light">
                                    <Link to={""}>
                                        <i className="ti-lock"></i> Lock Screen
                                    </Link>
                                </li>
                                {/* <li className="waves-effect waves-light">
                                    <i
                                        className="ti-layout-sidebar-left"
                                        onClick={handleLogout}
                                    ></i>{" "}
                                    Logout
                                </li> */}
                <li>
                  <button style={{ border: "none" }} onClick={logout}>
                    <i class="ti-layout-sidebar-left"></i>Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Topbar;
