import React, { useState, useEffect } from "react";
import { getData } from "../../Service/ApiMethod";
import { baseUrl, RIDESINTICKET } from "../../Service/service";
import { useParams } from "react-router-dom";
import Navnbar from "../Navnbar";
import "./ViewRide.css";

function ViewRide(id) {
    const [rideData, setRideData] = useState();
    const [imageUrl, setImageUrl] = useState();

    const param2 = useParams();
    // const imagePathURL = baseUrl + `/backend/rides/`;
    const imagePathURL = baseUrl + `/backend/rides/`; //dev server
    
    const handleTicketDeatail = () => {
        getData(RIDESINTICKET + param2.id)
            .then((response) => response.json())
            .then((res) => {
                setRideData(res.data);
                console.log("wwwwwwwwww", res.data);
                setImageUrl(res.data?.image_path);
            })
            .catch((err) => {
                console.log("Error in categories from Post Form", err);
                console.log(" code Error", err);
            });
    };
    // console.log("-->", ticketData);
    useEffect(() => {
        handleTicketDeatail();
    }, []);
    return (
        <>
            <Navnbar />
            <body>
    <div className="d" style={{position: "relative"}}> 
        <h3 id="heading" style={{textAlign: "center",marginTop:"2rem",marginBottom:"2rem"}}>
        RIDES
        </h3>
    </div>
    <div>
        <span >
               .
        </span> 
    </div>
</body>
            {rideData != undefined ? rideData.map((item, index) => {
                const combineImageUrl = imagePathURL + "/" + item?.image;
                console.log("combineImageUrl", combineImageUrl);
                return (

                    <div className="main-container-ticket">
                        <div className="imggDiv">
                            <img src={combineImageUrl} alt="Event-Profile-Image"  />
                        </div>
                        <div className="contentDiv">
                            <div><i class="fa fa-tag text-danger" aria-hidden="true"></i>  <b>Name:</b>  {item?.name}</div>
                            <div><i class="fa fa-tachometer text-warning" aria-hidden="true"></i> <b>Thrillometer:</b>  {item?.thrillometer}</div>
                            <div><i class="fa fa-users text-primary" aria-hidden="true"></i> <b>Age Group:</b>  {item?.for_ageGroup}</div>
                            <div><i class="fa fa-ticket text-success" aria-hidden="true"></i> <b>Discription:</b>  <p> &nbsp; &nbsp;  &nbsp; {item.description}</p> </div>
                        </div>
                    </div>

                );
            })
                : <h1 className="text-center mt-5">No Tickets Available</h1>}

        </>
    );
}

export default ViewRide;
