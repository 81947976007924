import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";

import { ADMINALLRIDES, ADMINGETRIDE, baseUrl, SEARCHBARALLRIDELIST } from "../../Service/service";
import { getData } from "../../Service/ApiMethod";
import { useState } from "react";
import Moment from "react-moment";
import ReactReadMoreReadLess from "react-read-more-read-less";
import ReadMoreAndLess from "react-read-more-less";
import { Result } from "postcss";
import { result } from "lodash";
import swal from "sweetalert";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
function AllRidesPage() {
    const [userData, setUserData] = useState();
    const [imageLink, setImageLink] = useState();
    const [filterdata, setFilterData] = useState([]);
    const [query, setQuery] = useState();
    const [rideDetail, setRideDetail] = useState();
    const [modal, setModal] = useState(false);
    const imageUrl = baseUrl+ `/backend/rides/`; //devServer
    // const imageUrl = baseUrl+`/backend/rides/`;
    const deleterow = (id) => {

        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete ticket",
            icon: "warning",
            dangerMode: true,
        }).then((willDelete) => {

            if (willDelete) {
                fetch(baseUrl +`/api/admin/delete-rides/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Origin: process.env.ORIGIN,
                        Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
                        host: baseUrl,
                        Accept: "*/*",
                    }
                }).then((result) => {
                    return result.json()
                }).then((data) => {
                    // console.log('data', data));
                    handleTicketDeatail()
                    swal(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )




                })
                // window.location.reload()

                // .catch((err) => {
                //     console.log("Error in categories from Post Form", err);
                //     console.log(" code Error", err);
                //     swal("Status!", "Retry !", "error");
                // })
            }


        }


        )


    }

    // console.log(data, "tarun")



    const handlesearch = (event) => {
        const getsearch = event.target.value;
        if (getsearch.length > 0) {
            getData(`${SEARCHBARALLRIDELIST}?name=${getsearch}`)
                .then((response) => response.json())
                .then((res) => {
                    setUserData(res.message);
                    // console.log(res.data);
                    setFilterData(res.message)

                })
            const searchdata = userData.filter((item) => item.name.toLowerCase().includes(getsearch));
            setUserData(searchdata)
        }
        else {
            handleTicketDeatail();
        }
        setQuery(getsearch)
    }



    const handleTicketDeatail = () => {
        getData(ADMINALLRIDES)
            .then((response) => response.json())
            .then((res) => {
                setUserData(res.data);
                setImageLink(res.data?.list?.image);
            })
            .catch((err) => {
                console.log("Error in categories from Post Form", err);
                console.log(" code Error", err);
            });
    };
    // console.log(userData, "tarun")
    console.log("image", imageLink);
    console.log("combineImageUrl", imageUrl + imageLink);
    useEffect(() => {
        handleTicketDeatail();
    }, []);
    const getRideDetail = (id) => {
        getData(ADMINGETRIDE + id)
          .then((response) => response.json())
          .then((res) => {
            console.log("VVVVVVV", res.data);
            setRideDetail(res.data);
          })
          .catch((err) => {
            console.log("Error in categories from Post Form", err);
            console.log(" code Error", err);
          });
      };
      console.log("DDDDDD", rideDetail);
    return (
        <>
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">
                    {/* top bar comes here  tag name nav  */}
                    <Topbar />
                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <SideNavbar />
                            <div className="pcoded-content">
                                {/* <div className="page-header">
                                    <div className="page-block">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="page-header-title">
                                                    <p className="m-b-12">
                                                        <i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <ul className="breadcrumb-title">
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            {" "}
                                                            <i className="fa fa-home"></i>{" "}
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>Bootstrap Tables
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>Basic Tables
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">
                                            <div className="page-body">
                                                <form class="form-material">
                                                    <div class="form-group form-primary">
                                                        <input type="text" name="footer-email" class="form-control" required="" onChange={(e) => handlesearch(e)} />
                                                        <span class="form-bar"></span>
                                                        <label class="float-label"><i class="fa fa-search m-r-10"></i>Search </label>
                                                    </div>
                                                </form>
                                                <Modal size="s" isOpen={modal} toggle={() => setModal(!modal)}>
                  <ModalHeader style={{ position: "relative" }}>
                    Ride Image
                    <button
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "0rem",
                      }}
                      class="close-btn border-0"
                      onClick={() => setModal(false)}
                    >
                      <i class="fa fa-close"></i>
                    </button>
                  </ModalHeader>
                  {rideDetail !== undefined
                                ? rideDetail.map((item, index) => {
                                    const combineImageUrl =
                                      imageUrl + "/" + item?.image;
                                    console.log(
                                      "combineImageUrl",
                                      combineImageUrl
                                    );
                                    return (
                  <ModalBody>
                    <div className="row">
                      <div className="col-6">
                      
                        <img
                          style={{width:"29rem"}}
                          src={combineImageUrl}
                          alt="Event-Profile-Image"
                        />
                      </div>
                    </div>
                  </ModalBody> );
                                  })
                                : null}
                </Modal>
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5>All Rides Table</h5>
                                                        {/* <div className="card-header-right">
                                                            <ul className="list-unstyled card-option">
                                                                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                                                                <li><i className="fa fa-window-maximize full-card"></i></li>
                                                                <li><i className="fa fa-minus minimize-card"></i></li>
                                                                <li><i className="fa fa-refresh reload-card"></i></li>
                                                                <li><i className="fa fa-trash close-card"></i></li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                    <div className="card-block table-border-style">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th> S.No</th>
                                                                        <th> Name</th>
                                                                        <th> Description</th>
                                                                        <th>Image</th>
                                                                        <th>Thrillometer</th>
                                                                        
                                                                        <th>Age Group</th>
                                                                        <th> Price</th>
                                                                        <th> Created</th>
                                                                        <th> Updated</th>
                                                                        <th> Action</th>

                                                                    </tr>
                                                                </thead>
                                                                {userData !== undefined ? userData.map((item, index) => {
                                                                    const combineImageUrl =
                                      imageUrl + "/" + item?.image;
                                    console.log(
                                      "combineImageUrl",
                                      combineImageUrl
                                    );
                                                                    return (
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    {
                                                                                        item.id
                                                                                    }
                                                                                </th>
                                                                                <td>
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </td>
                                                                                <td >
                                                                                    <ReadMoreAndLess className="read-more-content"
                                                                                        charLimit={
                                                                                            11
                                                                                        }
                                                                                        readMoreText="Read more"
                                                                                        readLessText="Read less"
                                                                                    >
                                                                                        {
                                                                                            item.description
                                                                                        }
                                                                                    </ReadMoreAndLess>
                                                                                </td>
                                                                                            <td>
                                                                                            <img
                          style={{width:"5rem"}}
                          src={combineImageUrl}
                          alt="Event-Profile-Image"
                          onClick={() => {
                                                setModal(true);
                                                getRideDetail(
                                                            item.id
                                                          );
                                              }}
                        />
                                                                                            </td>
                                                                                <td>
                                                                                    {
                                                                                        item.thrillometer
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.for_ageGroup
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.price
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                <Moment format="YYYY/MM/DD">
                                                                                    {
                                                                                        item.created_at
                                                                                    }
                                                                                </Moment>
                                                                                </td>
                                                                                <td>
                                                                                    <Moment format="YYYY/MM/DD">
                                                                                        {
                                                                                            item.updated_at
                                                                                        }
                                                                                    </Moment>
                                                                                </td>
                                                                                <td>
                                                                                    <span
                                                                                        className="btn  btn-primary btn-icon m-2 pl-2" style={{ cursor: "pointer", }}>
                                                                                        <i class="ti-trash" onClick={() => { deleterow(item.id) }}></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    );
                                                                }
                                                                ) : null}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="styleSelector"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllRidesPage;
