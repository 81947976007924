import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ADMINPROFILE, CHANGEPASSWORD } from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import { getData, postData } from "../../Service/ApiMethod";
import Topbar from "../AdminNavbar/Topbar";
import man from "../Imgs/faq_man.png"

function AdminProfileUpdate() {
  const [resData, setResData] = useState();
  const [newpassword, setNewPassword] = useState();
  const [oldPassword, setoldPassword] = useState();
  const [passwordConfirmation, setpasswordConfirmation] = useState();

  const getDetail = () => {
    getData(ADMINPROFILE)
      .then((response) => response.json())
      .then((res) => {
        setResData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  console.log(typeof resData);
  useEffect(() => {
    getDetail();
  }, []);

  const handleClick = () => {
    // alert(oldPassword);
    let dataObj = {
      password: newpassword,
      old_password: oldPassword,
      password_confirmation: passwordConfirmation,
    };
    postData(dataObj, CHANGEPASSWORD)
      .then((response) => response.json())
      .then((data) => {
        console.log("RRRRR", data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  useEffect(() => {
    handleClick();
  }, []);
  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              Bootstrap Tables
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              Basic Tables
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="card">
                          <div className="card-header">
                            <h5>Admin Profile Update </h5>
                          </div>
                          <div
                            className="p-3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                            }}
                          >
                            <form className="form-material">
                              <div
                                style={{
                                  boxShadow: "2px 2px 10px #000",
                                  height: "200px",
                                  borderRadius: "100px",
                                  width: "200px",
                                  float: "left",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={man}
                                  width="100%"
                                  alt=""
                                  data-dz-thumbnail="data-dz-thumbnail"
                                  style={{ borderRadius: "50%" }}
                                />
                              </div>
                              {/* <button
                                type="button"
                                className="btn btn-primary"
                                style={{ float: "left", marginTop: "10rem" }}
                              >
                                Update
                              </button> */}
                            </form>
                          </div>
                          {resData != undefined ? (
                            <form className="row p-3 form-material">
                              <div className="col-6 form-group form-default">
                                <input
                                  type="text"
                                  name="footer-email"
                                  className="form-control"
                                  required=""
                                  value={resData.name}
                                />
                                <span className="form-bar"></span>
                                <label className="float-label">Name</label>
                              </div>
                              <div className="col-6 form-group form-default">
                                <input
                                  type="email-text"
                                  name="footer-email"
                                  className="form-control"
                                  required=""
                                  value={resData.email}
                                />
                                <span className="form-bar"></span>
                                <label className="float-label">Email</label>
                              </div>
                            </form>
                          ) : null}
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h5>Change Password </h5>
                          </div>
                          <form className="p-3 form-material">
                            <div className="row pt-3">
                              <div className="col-4 form-group form-default">
                                <input
                                  type="text"
                                  name="footer-email"
                                  className="form-control"
                                  required=""
                                  onChange={(e) => {
                                    setoldPassword(e.target.value);
                                  }}
                                />
                                <span className="form-bar"></span>
                                <label className="float-label">
                                  Current Password
                                </label>
                              </div>
                              <div className="col-4 form-group form-default">
                                <input
                                  type="text"
                                  name="footer-email"
                                  className="form-control"
                                  required=""
                                  onChange={(e) => {
                                    setNewPassword(e.target.value);
                                  }}
                                />
                                <span className="form-bar"></span>
                                <label className="float-label">
                                  Enter New Password
                                </label>
                              </div>
                              <div className="col-4 form-group form-default">
                                <input
                                  type="email-text"
                                  name="footer-email"
                                  className="form-control"
                                  required=""
                                  onChange={(e) => {
                                    setpasswordConfirmation(e.target.value);
                                  }}
                                />
                                <span className="form-bar"></span>
                                <label className="float-label">
                                  Re-Enter New Password
                                </label>
                              </div>
                            </div>
                            <div
                              className="col-12 p-1"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleClick}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProfileUpdate;
