import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom'
import { GOOGLECALLBACK} from "../../Service/service";
import {  getData1 } from "../../Service/ApiMethod";

function GoogleCallback(props) {
    const [userDetails,setUserDetails ] = useState("No data");
    const [searchParams, setSearchParams] = useSearchParams()
    const code = searchParams.get('code')
    console.log(code)
   
    useEffect(() => {
        getData1(GOOGLECALLBACK + '?code='+code)
        .then((response) => response.json())
        .then((res) => {
            setUserDetails(res.data.name)
            // name
            // email
            // avatar
            // user
        })
        .catch((err) => {
          console.log(err)
        });
      });

  return (
    <div>
    <div>
        Code is = {code}
        
    </div>
    <div>
        User details = {userDetails}
    </div>
    </div>
  );
}
export default GoogleCallback;
