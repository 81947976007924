import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function SubAdminTopbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("fun_park_token") &&
      localStorage.getItem("email")
    ) {
      setIsLoggedIn(true);
    }
  }, []);
  
  const isSubAdmin = localStorage.getItem("isSubAdmin");
  const logoutsub = () => {
    localStorage.removeItem("fun_park_token");
    localStorage.removeItem("email");
    window.location.href = "/subadmin-login";

  
  };

  return (
    <nav className="navbar header-navbar pcoded-header">
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <Link
            className="mobile-menu waves-effect waves-light"
            id="mobile-collapse"
            to={""}
          >
            <i className="ti-menu"></i>
          </Link>
          <div className="mobile-search waves-effect waves-light">
            <div className="header-search">
              <div className="main-search morphsearch-search">
                <div className="input-group">
                  <span className="input-group-addon search-close">
                    <i className="ti-close"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Keyword"
                  />
                  <span className="input-group-addon search-btn">
                    <i className="ti-search"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Link to={""}>
            <img
              className="img-80 img-radius p-3"
              src="../../assets/images/logo.png"
              alt="User-Profile-Image"
              style={{ width: "180px" }}
            />
          </Link>
         
        </div>

        <div className="navbar-container container-fluid">
          <ul className="nav-left">
            <li>
              <div className="sidebar_toggle">
                <Link to={""}>
                  <i className="ti-menu"></i>
                </Link>
              </div>
            </li>
            <li className="header-search">
              <div className="main-search morphsearch-search">
                <div className="input-group">
                  <span className="input-group-addon search-close">
                    <i className="ti-close"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                  />
                  <span className="input-group-addon search-btn">
                    <i className="ti-search"></i>
                  </span>
                </div>
              </div>
            </li>
            
          </ul>
          <ul className="nav-right">
            <li className="header-notification">
              <Link to={""} className="waves-effect waves-light">
                <i className="ti-bell"></i>
                <span className="badge bg-c-red"></span>
              </Link>
              <ul className="show-notification">
                <li>
                  <h6>Notifications</h6>
                  <label className="label label-danger">
                    New
                  </label>
                </li>
                <li className="waves-effect waves-light">
                  <div className="media">
                    <img
                      className="d-flex align-self-center img-radius"
                      src="assets/images/avatar-2.jpg"
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 className="notification-user">
                        John Doe
                      </h5>
                      <p className="notification-msg">
                        Lorem ipsum dolor sit amet,
                        consectetuer elit.
                      </p>
                      <span className="notification-time">
                        30 minutes ago
                      </span>
                    </div>
                  </div>
                </li>
                <li className="waves-effect waves-light">
                  <div className="media">
                    <img
                      className="d-flex align-self-center img-radius"
                      src="assets/images/avatar-4.jpg"
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 className="notification-user">
                        Joseph William
                      </h5>
                      <p className="notification-msg">
                        Lorem ipsum dolor sit amet,
                        consectetuer elit.
                      </p>
                      <span className="notification-time">
                        30 minutes ago
                      </span>
                    </div>
                  </div>
                </li>
                <li className="waves-effect waves-light">
                  <div className="media">
                    <img
                      className="d-flex align-self-center img-radius"
                      src="assets/images/avatar-3.jpg"
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 className="notification-user">
                        Sara Soudein
                      </h5>
                      <p className="notification-msg">
                        Lorem ipsum dolor sit amet,
                        consectetuer elit.
                      </p>
                      <span className="notification-time">
                        30 minutes ago
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li className="user-profile header-notification">
              <Link to={""} className="waves-effect waves-light">
                <img
                  src="assets/images/avatar-4.jpg"
                  className="img-radius"
                  alt="User-Profile-Image"
                />
                <span>John Doe</span>
                <i className="ti-angle-down"></i>
              </Link>
              <ul className="show-notification profile-notification">
                
                {/* <li className="waves-effect waves-light">
                  <Link to={"/admin-profile-update"}>
                    <i className="ti-user"></i> Profile
                  </Link>
                </li> */}
                
                <li>
              
                    <button
                      style={{ border: "none" }}
                      onClick={logoutsub}
                    >
                      <i class="ti-layout-sidebar-left"></i>
                      Logout
                    </button>
              
                </li>{" "}
               
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default SubAdminTopbar
