import { baseUrl } from "./service";

export async function postDataImage(data, urlPath) {
    const finalData = new FormData();
    finalData.append("name", data.name);
    finalData.append("thrillometer", data.thrillometer);
    finalData.append("for_ageGroup", data.for_ageGroup);
    finalData.append("price", data.price);
    finalData.append("description", data.description);
    finalData.append("original_price", data.original_price);
    finalData.append("heading", data.heading);
    finalData.append("sale_price", data.sale_price);
    finalData.append("image", data.image);
    finalData.append("file_attachment", data.image);
    console.log("-->><<<---", finalData);
    const res = await fetch(baseUrl + urlPath, {
        method: "POST",
        mode: "cors",
        // body: "formData",
        body: finalData,
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            Origin: process.env.ORIGIN,
            Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
            host: baseUrl,
            Accept: "*/*",
            // AcceptEncoding: "gzip, deflate, br"
        },

        // body: JSON.stringify(data),
    });

    return await res;
}
export async function deleteData(data, urlPath) {
    const res = await fetch(baseUrl + urlPath, {
        method: "delete",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Origin: process.env.ORIGIN,
            Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
            host: baseUrl,
            Accept: "*/*",
            // AcceptEncoding: "gzip, deflate, br"
        },
        body: JSON.stringify(data),
    });

    return await res;
}
export async function patchData(data, urlPath) {
    const res = await fetch(baseUrl + urlPath, {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Origin: process.env.ORIGIN,
            Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
            host: baseUrl,
            Accept: "*/*",
            // AcceptEncoding: "gzip, deflate, br"
        },
        body: JSON.stringify(data),
    });

    return await res;
}

export async function postDataAuth(data, urlPath) {
    const res = await fetch(baseUrl + urlPath, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            // Origin: process.env.ORIGIN,
            Origin: process.env.ORIGIN || "https://jobs.atwpl.com",
            Authorization: "Bearer " + localStorage.setItem("fun_park_token"),
            host: baseUrl,
            Accept: "*/*",
            // AcceptEncoding: "gzip, deflate, br"
        },
        body: JSON.stringify(data),
    });

    return await res;
}

export async function getData(urlPath) {
    let accessTokenKey = "";
    accessTokenKey = localStorage.getItem("fun_park_token");
    const res = await fetch(baseUrl + urlPath, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            // Origin: 'http://localhost:3000'+"",

            Authorization: "Bearer " + accessTokenKey,
        },
    });
    return await res;
}
export async function getData1(urlPath) {
    const res = await fetch(baseUrl + urlPath, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            // Origin: 'http://localhost:3000'+"",
        },
    });
    return await res;
}
export async function postData(data, urlPath) {
    const res = await fetch(baseUrl + urlPath, {
        method: "POST",
        mode: "cors",
        body: "formData",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Origin: process.env.ORIGIN,
            Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
            host: baseUrl,
            Accept: "/",
            // AcceptEncoding: "gzip, deflate, br"
        },
        body: JSON.stringify(data),
    });
    return await res;
}
