import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getData } from "../../Service/ApiMethod";
import {
  GETDATEINVENTORY,
  GETDATEINVENTORYBASEDONID,
} from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";

const AlredyMangedInventory = () => {
  const [manageData, setMangeData] = useState();
  const [splitData, setSplitData] = useState();
  const [modal, setModal] = useState(false);

  const getDetail = () => {
    getData(GETDATEINVENTORY)
      .then((response) => response.json())
      .then((res) => {
        setMangeData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  const getValue = (id, e) => {
    console.log("^%^%^%^%", id);
    getData(GETDATEINVENTORYBASEDONID + id)
      .then((response) => response.json())
      .then((res) => {
        setSplitData(res.data.splits_data);
        console.log("%%%%", res.data.splits_data);
        // setMangeData(res.data);
        // console.log(res.data);
        // console.log("dfdf", manageData);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  //date search

  // const handledate = (event) => {
  //   const getdate_for = event.target.value;
  //   if (getdate_for.length > 0) {
  //     getData(`${REPORTPAGELIST}?date=${getdate_for}`)
  //       .then((response) => response.json())
  //       .then((res) => {
  //         setResData(res.data);
  //         // console.log(res.data);
  //         setFilterData(res.data)

  //       })
  //     const searchdata = resData.filter((item) => item.date_for.toLowerCase().includes(getdate_for));
  //     setResData(searchdata)
  //   } else {

  //     getDetail();
  //   }
  //   // setQuery(getmobile)
  // }

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <Modal size="s" isOpen={modal} toggle={() => setModal(!modal)}>
                  <ModalHeader style={{ position: "relative" }}>
                    Ticket Detail
                    <button
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "0rem",
                      }}
                      class="close-btn border-0"
                      onClick={() => setModal(false)}
                    >
                      <i class="fa fa-close"></i>
                    </button>
                  </ModalHeader>
                  <div className="row">
                  <h5 className="col-4">From</h5>
                              <h5 className="col-4">To</h5>
                              <h5 className="col-4">Increment</h5>
                              </div>
                  {splitData !== undefined
                    ? splitData.map((item, index) => {
                        return (
                          <ModalBody>
                            <div class="row">
                              
                            </div>

                            <div class="row">
                              <div className="col-4">
                                <Input type="text" value={item?.ticket_from} />
                              </div>
                              <div className="col-4">
                                <Input type="text" value={item?.ticket_to} />
                              </div>
                              <div className="col-4">
                                <Input
                                  type="text"
                                  value={item?.increament_percent}
                                />
                              </div>
                            </div>
                          </ModalBody>
                        );
                      })
                    : null}
                </Modal>
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card">
                              <div
                                className="card-header"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="row"
                                  style={{ paddingBottom: "0px" }}
                                >
                                  <div className="col-4">
                                    <h5>Managed Inventory Details</h5>
                                  </div>

                                  <div className="col-8 "style={{ bottom: "-6px", right: "-20rem" }}>
                                      <form className="form-material">
                                        <div className="form-group form-primary">
                                          <input
                                            type="date"
                                            className="form-control"
                                            style={{ width: "114px" }}
                                          />
                                          <span class="form-bar"></span>
                                        </div>
                                      </form>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "red",
                                    bottom: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "red",
                                    bottom: "2px",
                                  }}
                                ></div>
                              </div>

                              <div className="card-block table-border-style">
                                <div className="table-responsive">
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Capacity</th>
                                        <th>No. Of Splits</th>

                                        <th>Details</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {manageData != undefined
                                        ? manageData.map((item, index) => {
                                            return (
                                              <tr>
                                                <td>{item?.date_for}</td>

                                                <td>{item?.capacity}</td>
                                                <td>{item?.no_splits}</td>
                                                <td>
                                                 
                                                 <button class="btn waves-effect waves-light hor-grd btn-grd-primary "  onClick={(e) => {
                                                     setModal(true);
                                                     e.preventDefault();
                                                     getValue(item.id);
                                                   }}>
                                                Details
                                                 </button>
                                               </td>
                                                <tr />

                                               
                                              </tr>
                                            );
                                          })
                                        : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlredyMangedInventory;
