import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SideNavbar() {
    // const [isSubAdmin, setSubAdmin] = useState(false);
    // useEffect(() => {
    //     if (
    //         localStorage.getItem("fun_park_token") &&
    //         localStorage.getItem("email")
    //     ) {
    //         setSubAdmin(true);
    //     } else {
    //         setSubAdmin(false);
    //     }
    // }, []);
    // console.log("subadmin", isSubAdmin);
    return (
        <nav className="pcoded-navbar">
            <div className="sidebar_toggle">
                <Link to={""}><i className="icon-close icons"></i></Link>
            </div>
            <div className="pcoded-inner-navbar main-menu pt-5">

                <ul className="pcoded-item pcoded-left-item">
                    <li className="active">
                        <a href={"/admin-dashboard"} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-home"></i>
                            </span>
                            <span className="pcoded-mtext" data-i18n="nav.dash.main">Dashboard</span>
                            <span className="pcoded-mcaret"></span>
                        </a>
                    </li>
                </ul>

                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext" data-i18n="nav.basic-components.main">Rides</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/add-rides"} className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext" data-i18n="nav.basic-components.breadcrumbs">
                                        Add Rides
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                            <li className=" ">
                                <Link to={"/all-rides"} className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext" data-i18n="nav.basic-components.alert">
                                        All Rides
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext" data-i18n="nav.basic-components.main">Ticket</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/add-tickets"} className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext" data-i18n="nav.basic-components.breadcrumbs">
                                        Add Tickets
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                            <li className=" ">
                                <Link to={"/all-tickets"} className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext" data-i18n="nav.basic-components.alert">
                                        All Tickets
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                            <li className=" ">
                                <Link to={"/manage-tickets"} className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert">
                                        Manage Tickets
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext" data-i18n="nav.basic-components.main">Users</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/users"}className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert">
                                        All Users
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext"data-i18n="nav.basic-components.main">Inventory</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/manage-inventory"}className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert">
                                        Manage Inventory
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                            <li className=" ">
                                <Link to={"/alredy-manage-inventory"} className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert">
                                        Already Manged
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext"data-i18n="nav.basic-components.main">Orders</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/order-list"}className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert">
                                        Orders List
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext"data-i18n="nav.basic-components.main">Reports</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/report-list"}className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert" >
                                        Reports Page List
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu">
                        <Link to={""} className="waves-effect waves-dark">
                            <span className="pcoded-micon">
                                <i className="ti-layout-grid2-alt"></i>
                            </span>
                            <span className="pcoded-mtext"data-i18n="nav.basic-components.main">Miscellaneous</span>
                            <span className="pcoded-mcaret"></span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className=" ">
                                <Link to={"/notify-products"}className="waves-effect waves-dark">
                                    <span className="pcoded-micon">
                                        <i className="ti-angle-right"></i>
                                    </span>
                                    <span className="pcoded-mtext"data-i18n="nav.basic-components.alert" >
                                        Notify Ticket Release 
                                    </span>
                                    <span className="pcoded-mcaret"></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default SideNavbar;
