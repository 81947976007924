import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getData, getData1, postData } from "../../Service/ApiMethod";
// import { Scheduler } from "@aldabil/react-scheduler";
import swal from "sweetalert";
import {
  ADMINTICKETMANAGETICKET,
  GETDATEINVENTORY,
  TOTALCAPACITY,
} from "../../Service/service";

import "./manage.css";

function ManageInventory() {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [splitinput, setSplitInput] = useState();
  const [showRow, setShowRow] = useState(false);

  const [capacity, setCapacity] = useState();

  const [ticket_From, setTicketForm] = useState([]);
  const [ticket_To, setTicketTo] = useState([]);
  const [increment_To, setIncrementTo] = useState([]);
  const [totalCapacity, setTotalCapacity] = useState();
  const [multipleDates, setMultipleDates] = useState([]);

  const [dateData, setData] = useState([]);
  const [tgl, setTgl] = useState();

  const getDate = async () => {
    getData(GETDATEINVENTORY)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
        console.log("dateeeee", data.data);
      }).then(() => {
        handledates();
        console.log('>>>>>>>>><<<<<<<<<<', dateData)
      })

      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  useEffect(() => {
    getDate()
  }, [dateData])
  //   console.log(typeof resData);

  const handledates = () => {
    let dateArray = [];
    console.log('>>>>>>>>><<<<<<<<<<', dateData)
    for (let item of dateData) {
      console.log("date", item?.date_for);
      let datee = (item?.date_for).split("-").reverse().join("-");
      dateArray.push(datee);
      setMultipleDates(dateArray);
    }
  };
  console.log("multipleDates", multipleDates);

  const getDetail = () => {
    getData(TOTALCAPACITY)
      .then((response) => response.json())
      .then((res) => {
        setTotalCapacity(res);
        console.log("totttt", res);

      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  //   console.log(typeof resData);
  useEffect(() => {
    getDetail();
    // getDate();
  }, []);

  const handleinput = (e, splits) => {
    e.preventDefault();
    setSplitInput(e.target.value);
  };
  const handletable = (newData) => {
    setShowRow(true);
  };
  let listItem = {
    date_for: moment(startDate).format("YYYY-MM-D h:mm:ss "), 
    no_splits: splitinput, 
    capacity: totalCapacity,
    ticket_from: ticket_From,
    ticket_to: ticket_To,
    increament_percent: increment_To,
  };

  const handleClick = async (e) => {
    // e.preventDefault();
    console.log("listItem", listItem);
    await postData(listItem, ADMINTICKETMANAGETICKET)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const status = data.status;
        if (status === 200) {
          swal({
            title: "Inventory Added!",
            text: "Inventory Added Successfully!",
            icon: "success",
            button: "OK",
          });
        }
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  useEffect(() => {
    handleClick();
  }, []);

  const getTicketIndex = (idx, val) => {
    var tempArr = [...ticket_From];
    tempArr[idx] = val;
    setTicketForm(tempArr);
  };
  const getTicketIndexTo = (idx, val) => {
    var tempArr = [...ticket_To];
    tempArr[idx] = val;
    setTicketTo(tempArr);
    // setTotalCapacity(val)
  };
  const getIncrement = (idx, val) => {
    var tempArr = [...increment_To];
    tempArr[idx] = val;
    setIncrementTo(tempArr);
  };

  useEffect(() => {
    console.log(ticket_From, "tempArr");
    console.log(ticket_To, "tempArr2");
    console.log(increment_To, "tempArr3");
  }, [ticket_From, ticket_To, increment_To]);

  console.log("thhhh", moment(tgl).format("DD-MM-YYYY"));
  // const [drivedata, setDrivedata] = useState([]);
  // const dateId = () => {
  //     const filterdata = [];
  //     for (let item of data) {
  //         console.log("fillll", item);
  //         // if (item.date_for == tgl) {
  //         //     filterdata.push({ item });
  //         //     console.log("itttdwas", item);
  //         // }
  //     }
  // };
  // console.log("driveee", drivedata);
  useEffect(() => {
    setTgl();
    // dateId();
  }, []);

  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content"> 
                {/* <div className="page-header">
                                    <div className="page-block">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="page-header-title">
                                                    <p className="m-b-12">
                                                        <i
                                                            className="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                        ></i>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <ul className="breadcrumb-title">
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            {" "}
                                                            <i className="fa fa-home"></i>{" "}
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Bootstrap Tables
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Basic Tables
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-header">
                                <h5>Manage Inventory</h5>
                                <hr />
                                {/* <div className="row">
                                                                    <div
                                                                        className="col-12"
                                                                    > */}
                                {/* {event.data.info.map(
                                                                    (split) => {
                                                                        return ( */}
                                <form className="form-material">
                                  {totalCapacity != undefined ? (
                                    <div class="form-group form-primary">
                                      <input type="text" class="form-control" placeholder="Enter number of capacity" value={totalCapacity.capacity}
                                        onChange={(e) => { setTotalCapacity(e.target.value) }} id="capacity" />
                                      <span class="form-bar"></span>
                                      <label class="float-label">Total capacity:</label>
                                    </div>
                                  ) : null}
                                </form>
                                {/* );
                                                                    }
                                                                )} */}
                                {/* </div>
                                                                </div> */}

                                <div className="row">
                                  <div className="col-4 pt-1">
                                    <Calendar
                                      // onChange={
                                      //     setTgl
                                      // }
                                      minDate={new Date()} 
                                      value={tgl}
                                        tileDisabled={({ date }) => {
                                        let day = date.getDate();
                                        let month = date.getMonth() + 1;
                                        if (date.getMonth() < 10) { month = "0" + month }
                                        if (date.getDate() < 10) { day = "0" + day }
                                        const realDate = day + "-" + month + "-" + date.getFullYear();
                                        if (multipleDates?.find((val) => val === realDate)) {
                                          return "highlight";
                                        }
                                       }}
                                      tileClassName={({ date }) => {
                                        let day = date.getDate();
                                        let month = date.getMonth() + 1;
                                        if (date.getMonth() < 10) {
                                          month = "0" + month;
                                        }
                                        if (date.getDate() < 10) {
                                          day = "0" + day;
                                        }
                                        const realDate = day + "-" + month + "-" +
                                          date.getFullYear();
                                        if (multipleDates?.find((val) => val === realDate)) {
                                          return "highlight";
                                        }
                                      }}
                                      selected={date}
                                      onChange={(date) => setStartDate(date)}
                                    />
                                  </div>

                                  <div className="col-8 pt-4">
                                    <div className="row">
                                      <div className="col-6">
                                        <form className="form-material">
                                          {/* {event.data.info.map(
                                                                                        (
                                                                                            split
                                                                                        ) => {
                                                                                            return ( */}
                                          <div class="form-group form-primary">
                                            <input id="selectedDate" class="form-control"
                                              value={startDate ? moment(startDate.toString()).format("DD-MM-YYYY")
                                                : null
                                              }
                                            // value={moment(
                                            //     split.date_for
                                            // ).format(
                                            //     "DD-MM-YYYY"
                                            // )}
                                            />
                                            <span class="form-bar"></span>
                                            <label class="float-label">Selected date :</label>
                                          </div>
                                          {/* );
                                                                                        }
                                                                                    )} */}
                                        </form>
                                      </div>
                                      <div className="col-6 row">
                                        <div className="col-9">
                                          <form className="form-material">
                                            {/* {  event.data.info.map((split)=>{
                                                                    return( */}
                                            <div class="form-group form-primary">
                                              <input class="form-control" placeholder="No Of Splits"
                                                // value={split.no_splits}
                                                onChange={handleinput}
                                                type="number" />
                                              <span class="form-bar"></span>
                                            </div>
                                            {/* );
})} */}
                                          </form>
                                        </div>{" "}
                                        <div className="col-3">
                                          <button className="btn btn-primary"
                                            onClick={(e) => handletable(e.preventDefault())}>Apply
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col-7 text-danger" style={{ display: "flex", justifyContent: "center", }}>Ticket-Splits-Range
                                        </div>
                                        <div className="col-5 text-danger" style={{ display: "flex", justifyContent: "center", }}>Price-Increment(%)
                                        </div>
                                      </div>
                                      <div className="row">
                                        {showRow && new Array(parseInt(splitinput)).fill(1).map((el, idx) => {
                                          return (
                                            <div className="row " key={idx}>
                                              <span className="col-2 ">
                                                <input name="from_ticket[]"
                                                  // value={
                                                  //     (idx === 0) ? "0" : ""
                                                  // }
                                                  onChange={(event) => getTicketIndex(idx, event.target.value)}
                                                // readOnly={
                                                //     idx ===
                                                //     0
                                                // }
                                                />
                                              </span>
                                              <span className="col-2"></span>
                                              <span className="col-2">
                                                <input onChange={(event) => getTicketIndexTo(idx, event.target.value)} type="text"
                                                // value={(idx === (splitinput - 1)) ? 15000 : ' '}
                                                // readOnly={

                                                //     idx ===
                                                //     splitinput -
                                                //         1
                                                // }
                                                />
                                              </span>
                                              <span className="col-2"></span>:
                                              <span className="col-2">
                                                <input type="text" onChange={(event) =>
                                                  // setTicketFrom(
                                                  //     [
                                                  //         event
                                                  //             .target
                                                  //             .value,
                                                  //     ]
                                                  // )
                                                  getIncrement(idx, event.target.value)
                                                }
                                                />
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                      <div className="col-12" style={{ display: "flex", justifyContent: "center", }}>
                                        <button className="btn btn-primary" type="submit" onClick={(e) => handleClick(e.preventDefault())}>Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageInventory;
