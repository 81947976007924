import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postData } from "../../Service/ApiMethod";
import { USERLOGIN } from "../../Service/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const navigateToLogin = () => {
     
        navigate("/admin-dashboard");
        window.location.reload();
    };

    let objItem = {
        email: email,
        password: password,
        // user_types: "recruiter"
    };

    console.log("static", objItem);
    const handleLogin = async () => {
        await postData(objItem, USERLOGIN)
            .then((res) => res.json())

            .then((data) => {
                try {
                    console.log("From Api", data);

                    const token = data?.token;
                    const userType = data?.userType;
                    localStorage.setItem("fun_park_token", token);

                    if (token !== undefined && userType == 1) {
                        localStorage.setItem("fun_park_token", token);

                        localStorage.setItem("isSubAdmin", false);
                        navigateToLogin();
                    } else {
                        // alert(data?.error_message);
                        setMessage("Please Enter Correct Email or Password");
                    }
                } catch (err) {
                    console.log("error hgappended", err);
                    setError(err.message, "email already exists");
                    alert("Error", err);
                }
            });
    };

    const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
    return (
        <div>
            <body themebg-pattern="theme1">
                <div className="theme-loader">
                    <div className="loader-track">
                        <div className="preloader-wrapper">
                            <div className="spinner-layer spinner-blue">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                            <div className="spinner-layer spinner-red">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>

                            <div className="spinner-layer spinner-yellow">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>

                            <div className="spinner-layer spinner-green">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="login-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <form className="md-float-material form-material">
                                    <div className="text-center">
                                        {/* <img src="assets/images/logo.png" alt="logo.png" /> */}
                                    </div>
                                    <div className="auth-box card">
                                        <div className="card-block">
                                            <div className="row m-b-20">
                                                <div className="col-md-12">
                                                    <h3 className="text-center">
                                                        Sign In
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="form-group form-primary">
                                                <input type="email-text" name="email" className="form-control"
                                                    required=""
                                                    placeholder="Enter Email Address"
                                                    onChange={(e) => {
                                                        setEmail(
                                                            e.target.value
                                                        );
                                                    }}
                                                    id="email"
                                                />
                                                <span className="form-bar"></span>
                                                <label className="float-label">
                                                    Your Email Address
                                                </label>
                                            </div>
                                            <div className="form-group form-primary">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    // type="password"
                                                    name="password"
                                                    placeholder="Enter Your Pasword"
                                                    className="form-control"
                                                    required=""
                                                    autoComplete="current-password"
                                                    style={{width:"100%"}}
                                                    onChange={(e) => {
                                                        setPassword(
                                                            e.target.value
                                                        );
                                                    }}
                                                    id="password"
                                                />
                                                <i 
                                                     type="button"
                                                     onClick={togglePasswordVisibility}
                                                     aria-label="Toggle password visibility"
          
                                                >
                                                     < FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{position: "absolute", top:"22%",right:"6%"}}/>
                                                  </i>
                                                <span className="form-bar"></span>
                                                <label className="float-label">
                                                    Password
                                                </label>
                                            </div>
                                            <div className="row m-t-25 text-left">
                                                <div className="col-12">
                                                    <div className="checkbox-fade fade-in-primary d-">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                value=""
                                                            />
                                                            <span className="cr">
                                                                <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                                                            </span>
                                                            <span className="text-inverse">
                                                                Remember me
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="forgot-phone text-right f-right">
                                                        <Link
                                                            to={""}
                                                            className="text-right f-w-600"
                                                        >
                                                            {" "}
                                                            Forgot Password?
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-t-30">
                                                <div className="col-md-12">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"
                                                        onClick={handleLogin}
                                                    >
                                                        Sign in
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="text-danger text-center">
                                                {message}
                                            </div>
                                            {/* <hr />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <p className="text-inverse text-left m-b-0">
                                                        Thank you.
                                                    </p>
                                                    <p className="text-inverse text-left">
                                                        <Link to={""}>
                                                            <b>
                                                                Back to website
                                                            </b>
                                                        </Link>
                                                    </p>
                                                </div>
                                                <div className="col-md-2">
                                                    <img
                                                        src="assets/images/auth/Logo-small-bottom.png"
                                                        alt="small-logo.png"
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </div>
    );
}

export default Login;
