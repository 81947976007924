import React from 'react'
import ImageSlid from './ImageSlider/ImageSlid'
import Navnbar from './Navnbar'

const Home = () => {
  return (
    <div>
<Navnbar/>
<ImageSlid/>
    {/* <div className='row mb-5'>
      <div id='heading-outer' className='col-12'> 
          <h3 id="heading" >
              OUR PATNER’S
          </h3>
      </div>
    </div>
    <div className='row mb-5'>

      <div class="row text-center">
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="d-flex justify-content-center mb-4">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
              class="rounded-circle shadow-1-strong"
              width="150"
              height="150"
            />
          </div> 
        </div>
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="d-flex justify-content-center mb-4">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
              class="rounded-circle shadow-1-strong"
              width="150"
              height="150"
            />
          </div> 
        </div>
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="d-flex justify-content-center mb-4">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
              class="rounded-circle shadow-1-strong"
              width="150"
              height="150"
            />
          </div> 
        </div>
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="d-flex justify-content-center mb-4">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
              class="rounded-circle shadow-1-strong"
              width="150"
              height="150"
            />
          </div> 
        </div>
      </div>
    </div> */}
</div>
  )
}

export default Home