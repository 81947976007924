import React, { useEffect, useState } from "react";
import { getData } from "../../Service/ApiMethod";
import { USERPROFILE } from "../../Service/service";
import Navnbar from "../Navnbar";
import './Myprofile.css'
// import img1 from './Imgs/img22.jpg'
import "./Myprofile.css";
import man from "../Imgs/faq_man.png"

const MyProfile = () => {
    const [profileData, setProfileData] = useState();
    const getDetail = () => {
        // console.log("<<<<", ADMINGETTICKET);
        getData(USERPROFILE)
            .then((response) => response.json())
            .then((res) => {
                setProfileData(res);
                console.log("---profile", res);
            })
            .catch((err) => {
                console.log("Error in categories from Post Form", err);
                console.log(" code Error", err);
            });
    };

    useEffect(() => {
        getDetail();
    }, []);
    return (
        <>
            <Navnbar />
            {/* <div className="mp">
            <h2 className="dform">My Profile</h2> */}
            {/* <h2 style={{ marginLeft: "45%", marginTop: "30px" }}>My Profile</h2> */}
            {/* </div> */}
            <div class="col-lg-12 d-flex justify-content-center">
                {/* <div class="row g-0"> */}
                {/* <div class="col-lg-12 pe-lg-0"> */}
                <div class="card mb-3" style={{ marginTop: "70px" }}>
                <body>
    <div className="d" style={{position: "relative"}}> 
        <h3 id="heading" style={{textAlign: "center",marginTop:"2rem",marginBottom:"2rem"}}>
        PROFILE
        </h3>
    </div>
    <div>
        <span >
               .
        </span> 
    </div>
</body>
                    {/* <div className="card-header">
                        <h5 style={{ fontWeight: "600px"}}>Profile</h5>
                    </div> */}
                    <div class="card-body row">
                        <div class="col-4">
                            {/* <div class=""> */}
                            <div class="">
                                <img
                                    src={man}
                                    width="100%"
                                    alt=""
                                    data-dz-thumbnail="data-dz-thumbnail"
                                    style={{ borderRadius: "50%" }}
                                />
                            </div>
                            {/* </div> */}
                        </div>
                        {profileData !=
                            undefined ? (
                            <div class="col-8" style={{}}>
                                <tr className="border-bottom">
                                        <th className="ps-  "><i class="bi bi-person-circle text-danger" style={{ fontSize: "23px" }}></i> &nbsp;</th>
                                        <th className="ps-0 " style={{ fontSize: "19px" }}>{profileData.data.name}</th>&nbsp;
                                </tr>
                                <br />
                                <tr className="border-bottom">
                                        <th className="ps-  "><i class="bi bi-envelope-open text-info" style={{ fontSize: "23px" }}></i> &nbsp;</th>
                                        <th className="pe-0 " style={{ fontSize: "19px" }}>&nbsp;{profileData.data.email}</th>
                                </tr>
                                <br />
                                <tr className="border-bottom">
                                        <th className="ps-  "><i class="bi bi-telephone-plus text-success" style={{ fontSize: "23px" }}></i> &nbsp;</th>
                                        <th className="pe-0 " style={{ fontSize: "19px" }}>&nbsp;123456789</th>
                                </tr>
                                {/* <br/> */}
                                {/* <tr className="border-bottom">
                                    <th className="ps-5 ">add &nbsp;</th>
                                    <th className="pe-0 ">&nbsp;{profileData.data.email}</th>
                                </tr> */}
                                {/* <form class="row g-3"> */}

                                {/* <div class="col-lg-6">
                                        <label
                                            class="form-label"
                                            for="first-name"
                                        >
                                            Name
                                        </label>
                                        <input
                                            class="form-control"
                                            id="first-name"
                                            type="text"
                                            value={
                                                profileData
                                                    .data
                                                    .name
                                            }
                                        />
                                    </div> */}

                                {/* <div class="col-lg-6">
                                        <label class="form-label" for="email1">
                                            Email
                                        </label>
                                        <input
                                            class="form-control"
                                            id="email1"
                                            type="text"
                                            value={
                                                profileData
                                                    .data
                                                    .email
                                            }
                                        />
                                    </div> */}
                                {/* <div class="col-lg-6">
                                        <label class="form-label" for="email2">
                                            Phone
                                        </label>
                                        <input
                                            class="form-control"
                                            id="email2"
                                            type="text"
                                            value="+00000000000"
                                        />
                                    </div> */}
                                {/* <div class="col-lg-6">
                                        <label class="form-label" for="email3">
                                            Heading
                                        </label>
                                        <input
                                            class="form-control"
                                            id="email3"
                                            type="text"
                                            value="Software Engineer"
                                        />
                                    </div> */}
                                {/* <div class="col-12 d-flex justify-content-center" style={{ justifyContent: "sp", marginLeft: "0px" }}>
                                            <button class="btn btn-success btn-lg" type="submit" style={{ marginLeft: "" }}>UPDATE<></> </button> */}
                                {/* <button class="btn btn-success btn-lg" type="submit" style={{marginLeft:""}}><i class="fa fa-check" ></i> </button> */}
                                {/* <button class="btn btn-danger btn-lg" type="submit"><i class="fa fa-close"></i></button> */}
                                {/* </div> */}
                                {/* </form> */}
                            </div>) : null}
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default MyProfile;
