// Local And Server Base Urls

export const baseUrl = "https://apifps.atwpl.com/public"; //devServer
// export const baseUrl = "http://127.0.0.1:8000"; 


// End Points
// export const registrationUrl = "/register/";
export const ADMINALLRIDES = "/api/admin/all-rides";
export const ADMINBYTICKETRIDES = "/api/admin/get-ride-ticketId/";
export const USERLOGIN = "/api/user-login";
export const ADMINUSERS = "/api/admin/users";
export const ALLTICKETS = "/api/admin/all-tickets";
export const ADDRIDES = "/api/admin/add-rides";
export const ADDTICKETS = "/api/admin/add-tickets";
export const ADMINGETTICKET = "/api/admin/get-ticket?id=";
export const ADMINASSIGNRIDESTICKET = "/api/admin/assign-rides-tickets";
export const ALLACTIVETICKETS = "/api/all-tickets";
export const ADMINTICKETMANAGE = "/api/admin/tickets-manage";
export const TOTALCAPACITY = "/api/total-capacity"

export const USERREGISTER = "/api/user-register"
export const RIDESINTICKET = "/api/rides-in-ticket/"
export const USERPROFILE = "/api/user-profile"
export const ADMINTICKETMANAGETICKET = "/api/admin/manage-tickets"
export const GETDATEINVENTORY = "/api/admin/get-date-inventory"
export const GETTICKETDETAILS = "/api/get-ticket-details/";
export const BOOKTICKET = "/api/book-ticket";
export const BOOKINGSUMMARY = "/api/get-booking-summary/"
export const PAYMENTVERIFY = "/api/payment_verify"
export const ALLORDERS = "/api/admin/all-orders"
export const SEARCHBAR = "/api/admin/get-ticket"
export const EDITDETAILS = "/api/admin/alter-tickets/"
export const TICKETSMANAGE = "/api/admin/tickets-ride-manage"
export const REPORTPAGELIST = "/api/admin/reports"
export const MYORDERS = "/api/my-orders"
export const SEARCHBARUSERLIST = "/api/admin/get-users"
export const SEARCHBARALLRIDELIST = "/api/admin/get-rides"
export const GENERATETICKET = "/api/generate_ticket?order_id="
export const BULKRIDES = "/api/admin/bulk_rides"
export const SUBADMINALLRIDES = "/api/all-rides"
export const SUBADMINGETRIDES = "/api/get-ride/"
export const SUBADMINBOOKTICKET = "/api/sub-book-ticket"
export const PRICEATLOGIN = "/api/admin/price-at-login"
export const ADMINPROFILE = "/api/admin/profile"
export const DATEFROMTO = "/api/admin/filter-date"
export const ADMINGETRIDE = "/api/admin/get-ride/"
export const CHANGEPASSWORD = "/api/change-password"
export const RIDECOUNT = "/api/admin/ride-count"
export const ACTIVETICKET = "/api/admin/ticket-count"
export const ORDERCOUNT = "/api/admin/orders/total"
export const USERCOUNT = "/api/admin/all_users/list"
export const NOTIFICATION ="/api/admin/notification/unchecked"
export const NOTIFICATIONUPDATE = "/api/admin/updateOrder"
export const GRAPHCHART="/api/admin/graph-chart"
export const GETDATEINVENTORYBASEDONID= "/api/admin/get-date-inventory-id/"

//login with google
export const GOOGLEAUTH = "/api/google/auth"
export const GOOGLECALLBACK = "/api/callback/google"

/*

  const handleClick = () => {
    let dataObj = {
      applicant_id: param.userid,
    };
    postData(dataObj, GET_RECURITER_TIMESHEET)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setTimeSheet(data.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  useEffect(() => {
    handleClick();
    console.log(timeSheet);
  }, []);

 

const getDetail = () => {
    getData(RECURITER_PROFILE)
      .then((response) => response.json())
      .then((res) => {
        setResData(res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  console.log(typeof resData);
  useEffect(() => {
    getDetail();
  }, []);




in case of array----->

    {hire != undefined
        ? hire.map((item, index) => {
            return ( );
          })
        : null}
in case of object ----->
          {resData != undefined ? (
                <div className="col pr-0">
                  <h3 className="user-name">
                    {resData.first_name} {resData.last_name}
                  </h3>):null}
*/

// const deleterow = (id) => {
//   swal({
//     title: 'Are you sure?',
//     text: "Are you sure delete this ride",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes, delete it!'
//   }).then((result) => {
//     if (result.isConfirmed) {
//       swal(
//         'Deleted!',
//         'Your file has been deleted.',
//         'success'
//       )
//     }
//   })
//   // alert(id)
//   fetch('http://127.0.0.1:8000/api/admin/delete-rides/' + id, {
//     method: "DELETE",
//     headers: {
//       "Content-Type": "application/json",
//       Origin: process.env.ORIGIN,
//       Authorization: "Bearer " + localStorage.getItem("fun_park_token"),
//       host: baseUrl,
//       Accept: "*/*",
//     }
//   }).then((result) => {
//     return result.json()/* .then((resp) => {
//                 alert("delet")
//             })
//             handleTicketDeatail() */
//   }).then((data) => console.log('data', data));
