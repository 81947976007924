import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { getData } from "../../Service/ApiMethod";
import { ADMINUSERS, SEARCHBARUSERLIST } from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";

function Users() {
    const [userData, setUserData] = useState();

    const [filterdata, setFilterData] = useState([]);
    const [query, setQuery] = useState();


    const handlesearch = (event) => {
        const getsearch = event.target.value;
        if (getsearch.length > 0) {
            getData(`${SEARCHBARUSERLIST}?name=${getsearch}`)
                .then((response) => response.json())
                .then((res) => {
                    setUserData(res.message);
                    // console.log(res.data);
                    setFilterData(res.message)

                })
            const searchdata = userData.filter((item) => item.name.toLowerCase().includes(getsearch));
            setUserData(searchdata)
        }
        else {
            getDetail();
        }
        setQuery(getsearch)
    }


    const getDetail = () => {
        getData(ADMINUSERS)
            .then((response) => response.json())
            .then((res) => {
                setUserData(res.users);
                console.log(res.users);
            })
            .catch((err) => {
                console.log("Error in categories from Post Form", err);
                console.log(" code Error", err);
            });
    };

    useEffect(() => {
        getDetail();

    }, []);

    return (
        <>
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">
                    {/* top bar comes here  tag name nav  */}
                    <Topbar />
                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <SideNavbar />
                            <div className="pcoded-content">
                                {/* <div className="page-header">
                                    <div className="page-block">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="page-header-title">
                                                    <p className="m-b-12">
                                                        <i
                                                            className="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                        ></i>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <ul className="breadcrumb-title">
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            {" "}
                                                            <i className="fa fa-home"></i>{" "}
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Bootstrap Tables
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Basic Tables
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="p-30 p-b-5">
                                    <label className="float-label" style={{ fontsize: "5px" }}>
                                        <i className="fa fa-search m-r-10"></i>Search Users
                                    </label>
                                    <form className="form-material">
                                        <div className="form-group form-primary">
                                            <input type="text" name="footer-email" className="form-control" required="" onChange={(e) => handlesearch(e)} />
                                            <span className="form-bar"></span>
                                        </div>
                                    </form>
                                </div>

                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Users List</h5>
                                                    <div className="card-header-right">
                                                        <ul className="list-unstyled card-option">
                                                            <li>
                                                                <i className="fa fa fa-wrench open-card-option"></i>
                                                            </li>
                                                            <li>
                                                                <i className="fa fa-window-maximize full-card"></i>
                                                            </li>
                                                            <li>
                                                                <i className="fa fa-minus minimize-card"></i>
                                                            </li>
                                                            <li>
                                                                <i className="fa fa-refresh reload-card"></i>
                                                            </li>
                                                            <li>
                                                                <i className="fa fa-trash close-card"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="card-block table-border-style">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>Id</th>
                                                                    <th>
                                                                        Name
                                                                    </th>
                                                                    <th>
                                                                        Email
                                                                    </th>
                                                                    <th>
                                                                        Created
                                                                    </th>
                                                                    <th>
                                                                        Updated
                                                                    </th>
                                                                    <th>
                                                                        User
                                                                        Type
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            {userData !=
                                                                undefined
                                                                ? userData.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        {
                                                                                            item.id
                                                                                        }
                                                                                    </th>
                                                                                    <td>
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.email
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* {
                                                                                                  item.created_at
                                                                                              } */}

                                                                                        <Moment format="YYYY/MM/DD">
                                                                                            {
                                                                                                item.created_at
                                                                                            }
                                                                                        </Moment>
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* {
                                                                                                  item.created_at
                                                                                              } */}

                                                                                        <Moment format="YYYY/MM/DD">
                                                                                            {
                                                                                                item.updated_at
                                                                                            }
                                                                                        </Moment>
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.userType
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="styleSelector"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;
