import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SideNavbar from '../AdminNavbar/SideNavbar'
import Topbar from '../AdminNavbar/Topbar'
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { REPORTPAGELIST } from '../../Service/service';
import { getData } from "../../Service/ApiMethod";

function ReportPageList() {
  // const [startDate, setStartDate] = useState(new Date());
  const [resData, setResData] = useState()
  const [filterdata, setFilterData] = useState([]);
  // const [query, setQuery] = useState();

  // const handlemobile =() =>{

  // }

  const getDetail = () => {
    getData(REPORTPAGELIST)
      .then((response) => response.json())
      .then((res) => {
        setResData(res.data);
        console.log("vddsgfgsdfgsdfgs", res.data)
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  // mobile filter
  const handlemobile = (event) => {
    const getmobile = event.target.value;
    if (getmobile.length > 0) {
      getData(`${REPORTPAGELIST}?mobile=${getmobile}`)
        .then((response) => response.json())
        .then((res) => {
          setResData(res.data);
          // console.log(res.data);
          setFilterData(res.data)

        })
      const searchdata = resData.filter((item) => item.mobile.toLowerCase().includes(getmobile));
      setResData(searchdata)
    } else {

      getDetail();
    }
    // setQuery(getmobile)
  }

  // date filter

  const handledate = (event) => {
    const getdate_for = event.target.value;
    if (getdate_for.length > 0) {
      getData(`${REPORTPAGELIST}?date=${getdate_for}`)
        .then((response) => response.json())
        .then((res) => {
          setResData(res.data);
          // console.log(res.data);
          setFilterData(res.data)

        })
      const searchdata = resData.filter((item) => item.date_for.toLowerCase().includes(getdate_for));
      setResData(searchdata)
    } else {

      getDetail();
    }
    // setQuery(getmobile)
  }

  //handle ticket 

  const handleTicket = (event) => {
    const getticket_for = event.target.value;
    if (getticket_for.length > 0) {
      getData(`${REPORTPAGELIST}?ticket_for=${getticket_for}`)
        .then((response) => response.json())
        .then((res) => {
          setResData(res.data);
          // console.log(res.data);
          setFilterData(res.data)

        })
      const searchdata = resData.filter((item) => item.ticket_for.toLowerCase().includes(getticket_for));
      setResData(searchdata)
    } else {

      getDetail();
    }
    // setQuery(getmobile)
  }

 const handleRefresh = () => {
    window.location.reload();
  };

  // console.log(typeof resData);
  useEffect(() => {
    getDetail();
  }, []);
  return (
    <>
      <div id="pcoded" class="pcoded">
        <div class="pcoded-overlay-box"></div>
        <div class="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div class="pcoded-main-container">
            <div class="pcoded-wrapper">
              <SideNavbar />
              <div class="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="pcoded-inner-content">
                  <div class="main-body">
                    <div class="page-wrapper">
                      <div class="page-body">
                        <div className="card">
                          <div className="card-header">
                             {/* <div className="card-header-right">
                              <ul class="list-unstyled card-option">
                                <li><i class="fa fa fa-wrench open-card-option"></i></li>
                                <li><i class="fa fa-window-maximize full-card"></i></li>
                                <li><i class="fa fa-minus minimize-card"></i></li>
                                <li><i class="fa fa-refresh reload-card"></i></li>
                                <li><i class="fa fa-trash close-card"></i></li>
                              </ul>
                            </div> */}

                            <div className='row'>
                              <div className="col-2" style={{top:"1rem"}}>
                                <h5>Report List</h5>
                              </div>
                              <div className="col-1"></div>
                              <div className='col-2 pb-2' style={{ left: "75px" }}>
                                <form className="form-material">
                                  <div class="form-group form-primary mx-3">
                                    <input type="date" class="form-control" onChange={(e) => handledate(e)} />
                                    <span class="form-bar"></span>
                                  </div>
                                </form>
                              </div>

                              <div className='col-2 pb-2' style={{ left: "60px",  }}>
                                <form className="form-material">
                                  <div class="form-group form-primary">
                                    <input class="form-control"
                                      placeholder="Ticket No"
                                      type="text"
                                      onChange={(e) => handleTicket(e)}
                                    />
                                    <span class="form-bar"></span>
                                  </div>
                                </form>
                              </div>
                              <div className='col-2 pb-2' style={{ left: "57px", }}>
                                <form className="form-material">
                                  <div class="form-group form-primary">
                                    <input class="form-control"
                                      placeholder="Ticket Price"
                                      type="text"
                                    />
                                    <span class="form-bar"></span>
                                  </div>
                                </form>
                              </div>
                              <div className='col-2 pb-2' style={{ left: "60px"  }}>
                                <form className="form-material">
                                  <div class="form-group form-primary">
                                    <input class="form-control" placeholder="User Mobile No" type="text"
                                      onChange={(e) => handlemobile(e)}
                                    />
                                    <span class="form-bar"></span>
                                  </div>
                                </form>
                              </div>
                              <div className="col-1" style={{
                                right: "-60px",
                                fontSize: "22px",
                                top: "5px"
                              }}><i class="fa fa-refresh reload-card" onClick={handleRefresh}></i></div>
                            </div>
                          </div>
                          <div className="card-block table-border-style">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Name/Ticket</th>
                                    <th>Mobile. No.</th>
                                    <th>OrderID</th>
                                    {/* <th>Price</th> */}
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                {resData !== undefined ? resData.map((item, index) => {
                                  return (
                                    <tbody>
                                      <tr >
                                        <th scope="row">{item.id}</th>
                                        <td>{item.name}/{item.ticket_for}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.razorpay_order_id}</td>
                                        {/* <td>{550}</td> */}
                                        <td>{item.date_for}</td>
                                      </tr>
                                    </tbody>
                                  )
                                }
                                ) : null}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportPageList
