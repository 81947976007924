import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../Service/ApiMethod";
import { BOOKINGSUMMARY, PAYMENTVERIFY } from "../../Service/service";
import "./bookingSummery.css";
import moment from "moment";
import swal from "sweetalert";

const BookingSummary = () => { 
  const [orderData, setOrderData] = useState();
  const [userData, setUserData] = useState();

  const [orderId, setOrderId] = useState();
  const [salePrice, setSalePrice] = useState();

  const location = useLocation();
  console.log("location", location?.state?.userData); 
  const getUserData = () => {
    setUserData(location?.state?.userData);
  };

  const getDetail = async () => {
    getData(BOOKINGSUMMARY + location?.state?.userData.id)
      .then((response) => response.json())
      .then((res) => {
        setOrderData(res.data.ticket_details);
        // console.log("dattta ticket", res.data.ticket_details); 
        setSalePrice(res?.data?.ticket_details[0]?.sale_price);   

        // console.log("sale Price", res?.data?.ticket_details[0]?.sale_price);
        setOrderId(res?.data?.order_data[0]?.razorpay_order_id);
        // console.log("orderdattt", res?.data?.order_data[0]?.razorpay_order_id);

        // console.log("orderId", orderId);
      })
      // .catch((err) => {
      //   console.log("Error in categories from Post Form", err);
      //   console.log(" code Error", err);
      // });
  };
  const moveSummery = useNavigate();
  const navigateToSummery = (data) => {
    moveSummery("/confirmation", { state: { data: data } });
  };

  useEffect(() => {
    getDetail();
    getUserData();
    // handlePayment();
  }, []);
 
  const [status, setStatus] = useState();
  const handlePayment = () => {
    // console.log("{{{{{{", orderId);
    // console.log("!!!!!!!!!!!!!", salePrice); 

    let options = {
      key: "rzp_test_Gznr0a9O3MOf0c",
      amount: 5000,  
      currency: "INR",
      name: location?.state?.userData?.name,
      description: "Test Transaction",
      image: "https://example.com/your_logo", 
      order_id: orderId,

      handler: function  (response) {
        // setPaymentId(paymentId)
        // setpaymentOrderId(paymentOrderId)
        // setrazorpaySignature(razorpaySignature)

        // alert(); // sweet alert

        // console.log("resssssssss", response);
        let objItem = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: orderId,
          razorpay_signature: response.razorpay_signature,
        };
        // console.log("daasas", objItem);
        postData(objItem, PAYMENTVERIFY)
          .then((response) => response.json())
          .then((data) => {
            const status = data?.status;
            if (status === 200) {
              swal({
                position: "top-end",
                icon: "success",
                title: "Your payment is successful", 
                showConfirmButton: false,
                timer: 3000,
              });
              console.log("ddd", data);
              setStatus(data.data);
              navigateToSummery(data.data);
            }
          })
          // .catch((err) => {
          //   console.log("Error in categories from Post Form", err);
          //   console.log(" code Error", err);
          // });
      },
      prefill: {
        name: "Hemant Mehra",
        email: "hemantmehra@example.com",  
        contact: "8756521496",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: { color: "#3399cc" },
    };

    // console.log("@#@##@#@#@#@#@##@", options); 
    // eslint-disable-next-line no-undef
    var rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      swal({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: '<a href="">Why do I have this issue?</a>',
      });
    });
    document.getElementById("rzp-button1").onclick = function (e) {
      rzp1.open();
      e.preventDefault();
    };
  };

  useEffect(() =>{
    handlePayment();
  }, [orderId,salePrice])

  // console.log("orderdata",orderId);

  return (
    <>
      <div className="st_bt_top_header_wrapper float_left">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="st_bt_top_back_btn float_left">
                <Link to={"/"}><i className="fas fa-long-arrow-alt-left"></i> &nbsp;Back</Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="st_bt_top_center_heading float_left"><h3>Fun Park / Booking Summary</h3></div>
            </div>
          </div>
        </div>
      </div>
      <div className="st_dtts_wrapper float_left">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="st_dtts_left_main_wrapper float_left">
                <div className="row">
                  <div className="col-md-12">
                    <div className="st_dtts_bs_heading float_left"><p>Ticket Details</p></div>
                    <div className="st_dtts_ineer_box float_left">
                      <ul>
                        <li>
                          <span className="dtts1">Date</span>{" "}
                          <span className="dtts2">
                            {moment(userData?.date_for).format("D MMM YYYY")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="st_dtts_ineer_box float_left">
                      <ul>
                        <li>
                          <span className="dtts1">Name</span>{" "}
                          <span className="dtts2">{userData?.name}</span>
                        </li>
                        <li>
                          <span className="dtts1">Email</span>{" "}
                          <span className="dtts2">{userData?.email}</span>
                        </li>
                        <li>
                          <span className="dtts1">Moblie</span>{" "}
                          <span className="dtts2">{userData?.mobile}</span>
                        </li>
                        <li>
                          <span className="dtts1">Ticket</span>{" "}
                          <span className="dtts2">{userData?.ticket_for}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orderData != undefined ? orderData.map((item, index) => {
              return (
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="st_dtts_bs_wrapper float_left">
                        <div className="st_dtts_bs_heading float_left"><p>Booking summary</p></div>
                        <div className="st_dtts_sb_ul float_left">
                          <ul>
                            <li>
                              <h4>
                                {" "}
                                &nbsp;
                                {item?.heading}
                              </h4>
                              {/* <li>Ticket 1, Ticket 2</li> */}
                              {/* <br />( 2 Tickets ){" "} */}
                              <p></p> Original Price{" "}
                              <span>Rs .{item?.original_price}</span>
                            </li>
                            {/* <li>
                                                    Internet handling fees{" "}
                                                    <span>Rs.70.80</span>
                                                </li> */}
                          </ul>
                          <p>Discount <span>{item?.discount}</span></p>
                          {/* <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                Integrated GST (IGST) @ 18%{" "}    
                                                <span>Rs.60.00</span>
                                            </p> */}
                        </div>
                        <div className="st_dtts_sb_h2 float_left">    
                          <p>{item?.description}</p>
                          <h3>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sub total{" "}  
                            <span>
                              Rs. {item?.sale_price}
                              {/* {ticket.sale_price} */}
                            </span>
                          </h3>

                          <h5>
                            Payable Amount{" "}     
                            <span>
                              Rs. {item?.sale_price}
                              {/* {ticket.sale_price} */}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )})
              : null}
          </div>
          <div className="col-md-12">
            <div className="st_cherity_btn float_left">
              {/* <h3></h3> */}
              <ul>
                <li> 
                  <button
                    className="btn btn-outline-dark"style={{marginTop:"25px"}}
                    id="rzp-button1"
                  //  onclick={(e)=>setOrderId(e.target.razorpay_order_id)}
                  >
                    <i className="flaticon-tickets"></i> &nbsp;Proceed to pay{" "}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default BookingSummary;
