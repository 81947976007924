import React from "react";
import RoutesAll from "./Routes/RoutesAll";
import "bootstrap/dist/css/bootstrap.min.css";
 

const App = () => {
  return <RoutesAll />;
};

export default App;
