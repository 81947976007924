// API code {original}

import React, { useEffect, useRef, useState, Component } from "react";
import "./explore.css";

import Navnbar from "./Navnbar";

import { useNavigate } from "react-router-dom";
import { getData, getData1 } from "../Service/ApiMethod";
import { ALLACTIVETICKETS, baseUrl } from "../Service/service";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import megasale from "./Imgs/megasale.jpg";
import LoginComp from "./logincomp/LoginComp";
import { style } from "@mui/system";
import star from "./Imgs/star.png";

const Explore = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };
  const [imageLink, setImageLink] = useState();
  const [ticketData, setTicketData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loginpopup, setLoginpopup] = useState(false);
  const [modal, setModal] = useState(false);

  const ref = useRef(null);
  const navigate = useNavigate();
  const imagePathURL = baseUrl+"backend/tickets/"; //devServer
  // const imagePathURL = baseUrl+"backend/tickets/";

  const handleClick = (id) => {
    navigate(`/TicketDetail/ViewRide/${id}`);
  };

  const handleBook = (id) => {
    const logindata = localStorage.getItem("isLoggedIn");

    console.log("rideidd", id);
    if (logindata) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
      navigate(`/booknow/${id}`);
    } else {
      setModal(true);
    }
    // ref.current?.scrollIntoView({ behavior: "smooth" });
    // navigate(`/booknow/${id}`);
  };
                                         
  const handleTicketDeatail = () => {
    if (myBooleanValue) {
      getData(ALLACTIVETICKETS)
        .then((response) => response.json())
        .then((res) => {
          console.log("ddddddd", res);
          setImageLink(res.data?.list[0]?.image);
          setTicketData(res.data?.list);
          setImageUrl(res.data?.image_path);
        })
        .catch((err) => {
          console.log("Error in categories from Post Form", err);
          console.log(" code Error", err);
        });
    } else {
      getData1(ALLACTIVETICKETS)
        .then((response) => response.json())
        .then((res) => {
          console.log("ddddddd", res);
          setImageLink(res.data?.list[0]?.image);
          setTicketData(res.data?.list);
          setImageUrl(res.data?.image_path);
        })
        .catch((err) => {
          console.log("Error in categories from Post Form", err);
          console.log(" code Error", err);
        });
    }
  };
  console.log("-->", ticketData);

  console.log("imageUrl", imageUrl);
  console.log("image", imageLink);
  console.log("combineImageUrl", imageUrl + imageLink);

  useEffect(() => {
    handleTicketDeatail();
  }, []);
  const myValue = localStorage.getItem("isLoggedIn");
  const myBooleanValue = myValue === "true";
  
  const handleOrder = () => {
    navigate("/MyOrder");
  };

  return (
    <>
      <Navnbar />
      <div className="card-wrapper nn ">
        <Slider {...settings}>
          {ticketData != undefined
            ? ticketData?.map((item, index) => {
                console.log("<<<<>>>>>", ticketData);
                const combineImageUrl = imageUrl + "/" + item?.image;
                console.log("combineImageUrl", combineImageUrl);
                return (
                  <div
                    className="cardp cnt"
                    style={{ marginTop: "20%" }}
                    key={index}
                  >
                    {/* <h2>Pause On Hover</h2> */}

                    <>
                      <div className="card-wrapper bdr" key={index}>
                        <div
                          className="card"
                          style={{ width: "18rem", borderRadius: "20px" }}
                        >
                          <div className="cover">
                            <button
                              className="b1"
                              type="submit"
                              onClick={() => handleClick(item?.id)}
                            >
                              View Rides
                            </button>
                            <br />
                            {myBooleanValue && item?.was_booked ? (
                              <button
                                className="b2"
                                type="submit"
                                onClick={() => {
                                  handleOrder();
                                }}
                              >
                                My Orders
                              </button>
                            ) : (
                              <button
                                className="b2"
                                type="submit"
                                onClick={(e) => {
                                  handleBook(item?.id);
                                }}
                              >
                                Book Now
                              </button>
                            )}
                          </div>
                          {/* {loginpopup && 

            } */}

                          {/* <img src={megasale} class="d-block w-100" alt="..." /> */}
                          <img
                            src={combineImageUrl}
                            className="card-img-top"
                            alt="..."
                            style={{ width: "18rem", height: "18rem" }}
                          />

                          {/* 

                                                <div className="card-body border border-danger" >
                                                    <div className="text" style={{ color: "" }}>
                                                        <center><h3>{item?.heading}</h3></center>
                                                    </div>{" "}
                                                    <h6 className="card-title" style={{ color: "545151" }}>
                                                        {" "}
                                             
                                                        <span className="text-white p-1 rounded" style={{background:"red"}}>
                                                            {item?.discount}% OFF

                                                        </span>{" "}

                                                        <i className="fa fa-certificate" aria-hidden="true" style={{ fontSize: "20px", color: "red", marginLeft: "4.5rem", }} >

                                                            <span style={{color:"grey"}}> Rs.<del>{item?.original_price}</del></span>
                                                        </i>
                                                    </h6>
                                                    <h6 className="card-title" >
                                                        <span  className="text-white p-1 rounded" style={{background:"blue"}}>Ticket Price</span>
                                                        <i className="fa fa-certificate" aria-hidden="true" style={{ fontSize: "20px", color: "blue", marginLeft: "4.1rem", }}>
                                                            <span style={{color:"#000"}}>Rs.{item?.sale_price}</span>
                                                        </i>
                                                    </h6>
                                                </div> */}

                          <div>
                            <div
                              className="pt-1"
                              style={{ marginLeft: "15px" }}
                            >
                              <h3>{item?.heading}</h3>
                            </div>

                            <div
                              className="w-50 ps-3"
                              style={{ height: "90px", float: "left" }}
                            >
                              <div className="text-dark">Available Seats:</div>

                              {myBooleanValue || item.price_at_login == 1 ? (
                                <>
                                  <span
                                    style={{ fontSize: "22px", color: "#000" }}
                                  >
                                    {" "}
                                    ₹{" "}
                                    <del
                                      style={{
                                        fontSize: "14px",
                                        color: "grey",
                                      }}
                                    >
                                      {item?.original_price}
                                    </del>
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "22px",
                                      color: "#000",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item?.sale_price}
                                  </span>
                                </>
                              ) : (
                                <span style={{ color: "red" }}>
                                  Please Login <br /> to view price !{" "}
                                </span>
                              )}
                            </div>

                            <div
                              className="w-50"
                              style={{ height: "100px", float: "left" }}
                            >
                              <img
                                src={star}
                                alt="offer"
                                style={{ width: "90%", height: "90%" }}
                              />
                              <p
                                className="text-dark"
                                style={{
                                  position: "absolute",
                                  fontWeight: "bold",
                                  margin: "-55px 0px 0px 32px",
                                }}
                              >
                                {Math.ceil(
                                  ((item?.original_price - item?.sale_price) *
                                    100) /
                                    item?.original_price
                                )}
                                % OFF
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        <LoginComp
                          setModal={setModal}
                          modal={modal}
                          id={item?.id}
                        />
                      }
                    </>
                  </div>
                );
              })
            : null}
        </Slider>
      </div>
    </>
  );
};

export default Explore;
