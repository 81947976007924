import React, { useState, useRef } from 'react';
import 'font-awesome/css/font-awesome.min.css'; 
import './LoginComp.css'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { Link, useNavigate } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
// import Explore from './Explore';
import { postData } from '../../Service/ApiMethod';
import { USERLOGIN, USERREGISTER } from "../../Service/service";
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


function LoginComp(props) {
    // const { loginWithRedirect } = useAuth0();
    // const { loginWithRedirect } = useAuth0();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(false);
    const ref = useRef(null);
    const navigate = useNavigate();
    const [register, setRegister] = useState(false);
    const [message, setMessage] = useState("");

    const [regName, setRegName] = useState();
    const [regEmail, setRegEmail] = useState();
    const [regPassword, setRegPassword] = useState();

    const [passwordConfirmation, setPasswordCofirmation] = useState();

    const [isAuthenticated, setIsAuthenticated] = useState(false);


    const [errorstate, setErrorState] = useState("");
    const [name, setName] = useState()
    const [emailid, setEmailId] = useState()
    const [passwordid, setErrorPassword] = useState()
    const [repassword, setRePassword] = useState()
    const [descriptionstate, setDescriptionState] = useState("")



    const move = useNavigate();
    const navigateToLoginPage = () => {
        move("/explore");
    };

    const setModal = props?.setModal
    const modal = props?.modal
    const id = props?.id

    let regItem = {
        name: regName,
        email: regEmail,
        password: regPassword,
        userType: "2",
        password_confirmation: passwordConfirmation,
    };

    console.log("static", regItem);
    const handleReagister = async (e) => {

        setIsAuthenticated(true);
        await postData(regItem, USERREGISTER)
            .then((res) => res.json())

            .then((data) => {
                try {
                    console.log("From Api", data);
                    const token = data?.token;
                    const userType = data?.userType;
                    const status = data?.status;
                    if (token !== undefined) {
                        localStorage.setItem("fun_park_token", token);
                        localStorage.setItem("isLoggedIn", true);
                        
                        navigateToLoginPage();
                        setIsAuthenticated(true);
                    } else if (status === 400) {
                        setErrorState(data.message)
                    } else if (status === 200) {
                        setErrorState(swal({
                            title: "Good job!",
                            text: "Ticket Added Successfuly",
                            icon: "success",
                            button: "OK",
                        }))
                    } else if ((data.stauts) === 401) {

                        setName(data.errors.name)
                        setEmailId(data.errors.email)
                        setErrorPassword(data.errors.password)
                        setRePassword(data.errors.password_confirmation)
                        // setNameState(data.errors.name)

                    } else {
                        // alert(data?.error_message);
                        setMessage("Please Enter Correct Email or Password");
                    }
                } catch (err) {
                    console.log("error hgappended", err);
                    setError(err.message, "email already exists");
                    setMessage(err.message, "email already exists");

                    // alert("Error", err);
                }
            });
    };

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
        navigate("/explore");
    };

    const handleClickProfile = () => {
        navigate("/myprofile");
    };

    const nav = useNavigate();

    const navigateToLogin = () => {
        navigate(`/booknow/${id}`);
    };
    const handleClickOrder = () => {
        navigate("/myorder")
    }

    const handleClickHome = () => {
        navigate("/")
    }




    let objItem = {
        email: email,
        password: password,
    };

    console.log("static", objItem);

    const handleLogin = async (id) => {
        


        await postData(objItem, USERLOGIN)
            .then((res) => res.json())

            .then((data) => {
                try {
                    console.log("From Api", data);
                    const token = data?.token;
                    const userType = data?.userType;
                    if (token !== undefined && userType == 2) {
                        localStorage.setItem("isLoggedIn", true);
                        localStorage.setItem("fun_park_token", token);
                        navigateToLogin();
                        setIsAuthenticated(false);
                        // setIsLoggedIn(true);
                    } else {
                        // alert(data?.error_message);
                        setMessage("Please Enter Correct Email or Password");
                    }
                } catch (err) {
                    console.log("error hgappended", err);
                    setError(err.message, "email already exists");

                    // alert("Error", err);
                }
            });
        //     setModal(false)
        // navigate(`/booknow/${id}`);
    };

    // function handleLogout() {
    //     // Clear the token
    //     localStorage.clear();

    //     // Update state to reflect the user being logged out
    //     setIsLoggedIn(false);
    //   }
    const navigateto = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigateto("/");
        setIsAuthenticated(false);
    };
    // const login = () => {

    //     navigateto("/");
    //     setIsAuthenticated(false);
    // };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword((prevState) => !prevState);
    };
  

    return (
        <>

             {/* LogIn */}
                    <Modal
                        size="s"
                        isOpen={modal}
                        toggle={() => setModal(!modal)}
                    >
                        <ModalHeader>
                            Sign in
                            <button
                                className="close-btn border-0"
                                onClick={() => setModal(false)}
                            >
                                <i className="fa fa-close"></i>
                            </button>
                        </ModalHeader>

                        <ModalBody>
                            <form>
                                {/* <!-- Email input --> */}
                                <div style={{ display: "flex", justifyContent: "center", color: "red" }}>{errorstate}</div>
                                <div className="form-outline mb-2">
                                    <input
                                        type="email"
                                        id="form2Example1"
                                        className="form-control"
                                        placeholder="Email"
                                        required
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                    <label
                                        className="form-label"
                                        for="form2Example1"
                                    ></label>
                                </div>

                                <div className="form-outline mb-4">
      {/* <label htmlFor="password">Password:</label> */}
      <div className="form-outline mb-4">
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          className="form-control "
          placeholder="Enter Password"
          required
          autoComplete="current-password"
          style={{width:"100%"}}
          onChange={(e) => {
            setPassword(e.target.value); 
          }}
        />
        <i 
          type="button"
          onClick={togglePasswordVisibility}
          aria-label="Toggle password visibility"
          
        >
          < FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{position: "absolute", top:"28%",right:"6%"}}/>
        </i>
      </div>
    </div>

                             
                                <div className="row mb-4">
                                    <div className="col d-flex justify-content-center">
                                        
                                        <div className="form-check" style={{marginLeft:"45px"}}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="form2Example31"
                                                checked
                                            />
                                            <label
                                                className="form-check-label"
                                                for="form2Example31"
                                            style={{marginLeft:"-15px"}}>
                                                {" "}
                                                Remember me{" "}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col">
                                       
                                        <a href="#!">Forgot password?</a>
                                    </div>
                                </div>

                               

                                <button
                                    type="button"
                                    className="btn btn-danger  mb-2 w-90" 
                                    onClick={()=>{handleLogin(id)}}
                                    style={{marginLeft:"12rem"}}
                                >
                                    Sign in
                                </button>
                                <div className="text-danger text-center">
                                    {message}
                                </div>

                              
                                <div className="text-center" style={{onhover:"curserpointer"}}>
                                    <p>
                                        Not a member?
                                        <a
                                            onClick={() => {
                                                setModal(false);
                                                setRegister(true);
                                            }}
                                             >
                                              <div className="btn btn-primary  mb-2 w-90" style={{ backgroundColor:"" }}>
                                                   Register
                                              </div>
                                        </a>
                                    </p>
                                    <p>or sign up with:</p>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1 " style={{fontSize:"20px"}}
                                    >
                                        <i className="fa fa-google"></i>
                                    </button>

                                   
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>

                    {/* register */}

                    <Modal
                        size="s"
                        isOpen={register}
                        toggle={() => setRegister(!register)}
                    >
                        <ModalHeader>
                            Sign Up
                            <button
                                className="close-btn border-0"
                                onClick={() => setRegister(false)}
                            >
                                <i className="fa fa-close"></i>
                            </button>
                        </ModalHeader>
                        <ModalBody>
                            <form>
                                <div style={{ display: "flex", justifyContent: "center", color: "red" }}>{errorstate}</div>
                                <div class="form-outline mb-2">

                                    <input
                                        type="text" id="form2Example1" className="form-control" placeholder="Name"
                                        onChange={(e) => { setRegName(e.target.value); }} />
                                    <div style={{ color: "red" }}>{name}</div>
                                </div>

                                {/* <div className="form-outline mb-2">
                                    <input type="text" id="form2Example1" className="form-control" placeholder="Last Name" />
                                    <label className="form-label" for="form2Example1"></label>
                                </div> */}

                                <div class="form-outline mb-2">
                                    <input type="email" id="form2Example1" className="form-control" placeholder="Enter Email id" onChange={(e) => { setRegEmail(e.target.value); }} />
                                    <div style={{ color: "red" }}>{emailid}</div>
                                </div>

                                {/* <!-- Password input --> */}
                                <div class="form-outline mb-4">
                                    <input type="password" id="form2Example2" className="form-control " placeholder="Password"
                                        onChange={(e) => { setRegPassword(e.target.value); }} />
                                    <div style={{ color: "red" }}>{passwordid}</div>
                                </div>

                                <div class="form-outline mb-4">
                                    <input type="password" id="form2Example2" className="form-control " placeholder="Re-Enter Password" onChange={(e) => { setPasswordCofirmation(e.target.value); }} />
                                    <div style={{ color: "red" }}>{repassword}</div>
                                </div>

                                {/* <!-- 2 column grid layout for inline styling --> */}
                                <div className="row mb-4">
                                    <div className="col d-flex justify-content-center">
                                        {/* <!-- Checkbox --> */}
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="form2Example31"
                                                checked />
                                            <label className="form-check-label" for="form2Example31">
                                                {" "}
                                                Remember me{" "}
                                            </label>
                                        </div>
                                    </div> 
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-danger btn mb-4 w-90" 
                                    onClick={handleReagister} style={{marginLeft:"11rem"}}
                                >
                                    Register Now
                                </button>

                                {/* <!-- Register buttons --> */}
                                <div className="text-center">
                                    {/* <p>
                                        Not a member?
                                        <a href="#!" type="submit">
                                            Register
                                        </a>
                                    </p> */}
                                    <p>or sign up with:</p>
                                    {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-facebook-f"></i>
                                    </button> */}

                                    <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1" style={{fontSize:"20px"}}
                                    >
                                        <i className="fa fa-google"></i>
                                    </button>

                                    {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-twitter"></i>
                                    </button> */}

                                    {/* <button
                                        type="button"
                                        className="btn btn-link btn-floating mx-1"
                                    >
                                        <i className="fa fa-linkedin"></i>
                                    </button> */}
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>
                 
                    


            
        </>
    );
}

export default LoginComp;
