// import { Button } from "bootstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ADDRIDES } from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";
import { postDataImage } from "../../Service/ApiMethod";
import swal from "sweetalert";
import { DropdownButton, Dropdown } from "react-bootstrap";

const AddRidesPage = () => {
    // const fileChangedHandler = (event) => {
    //     const image = event.target.files[0];
    //     console.log("-----0----", image);
    // };

    const [name, setName] = useState("");
    const [thrillometer, setThrillometer] = useState("");
    const [ageGroup, setAgeGroup] = useState("");
    const [image, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [price, SetPrice] = useState("");
    const [userType, setUserType] = useState("SELECT");
    //error validation
    const [errorstate, setErrorState] = useState("");
    const [namestate, setNameState] = useState();
    const [thrillometerstate, setThrillometerState] = useState();
    const [ageGroupstate, setAgeGroupState] = useState();
    const [imagestate, setImageState] = useState();
    const [descriptionstate, setDescriptionState] = useState("");
    const [pricestate, setPriceState] = useState();

    // const [file, setFile] = useState(null);
    // const [file, setFile] = useState(null)

    const handleClick = async () => {
        // e.preventDefault();

        await postDataImage(
            {
                name: name,
                thrillometer: thrillometer,
                for_ageGroup: ageGroup,
                price: price,
                image: image,
                description: description,
            },
            ADDRIDES
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("!>>>>>>>>",data);
                try {
                    // console.log("hi tarun",data);
                    const status = data?.status;
                    // alert(status)
                    if (status === 400) {

                        setErrorState(data.message);
                    } else if (status === 200) {
                        setErrorState(
                            swal({
                                title: "Ride Added!",
                                text: "Ride Added Successfully",
                                icon: "success",
                                button: "OK",
                            })
                        );
                        window.location.reload();
                        // handleClick();
                    } else if (status === 401) {

                        setNameState(data.errors.name);
                        setAgeGroupState(data.errors.ageGroup);
                        setThrillometerState(data.errors.thrillometer);
                        setPriceState(data.errors.price);
                        setDescriptionState(data.errors.description);
                        setImageState(data.errors.image);
                    }
                } catch (error) {
                    console.log("---", error);
                }
            })
            .catch((err) => {
                console.log("Error in categories from Post Form", err);
                console.log(" code Error", err);
            });
    };

    return (
        <>
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box"></div>
                <div className="pcoded-container navbar-wrapper">
                    {/* top bar comes here  tag name nav  */}
                    <Topbar />
                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <SideNavbar />
                            <div className="pcoded-content">
                                {/* <div className="page-header">
                                    <div className="page-block">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="page-header-title">
                                                    <p className="m-b-12">
                                                        <i
                                                            class="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                        ></i>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <ul className="breadcrumb-title">
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            {" "}
                                                            <i className="fa fa-home"></i>{" "}
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Bootstrap Tables
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Basic Tables
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                        <div className="page-wrapper">
                                            <div className="page-body">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5>Add Rides</h5>
                                                            </div>
                                                            <div className="card-block">
                                                                <div style={{ display: "flex", justifyContent: "center", color: "red", }}>{errorstate}
                                                                </div>
                                                                <form className="form-material">
                                                                    <div className="form-group form-default">
                                                                        <input type="text" name="footer-email" className="form-control" required
                                                                            onChange={(e) => { setName(e.target.value) }} />
                                                                        <p className="float-label">Name</p>
                                                                        <div style={{ color: "red", }}>{namestate}</div>
                                                                    </div>
                                                                    <select
                                                                        style={{ border: "none" }} class="form-select"
                                                                        aria-label="Default select example"
                                                                        onChange={(e) => setAgeGroup(e.target.value)}>
                                                                        <option selected>selectageGroup..</option>
                                                                        <option value="Adults">Adults</option>
                                                                        <option value="Kids">Kids</option>
                                                                        <div style={{ color: "red", }}>{ageGroupstate}</div>
                                                                    </select>
                                                                    <hr />
                                                                    <select class="form-select" aria-label="Default select example" style={{ border: "none" }} onChange={(e) => setThrillometer(e.target.value)
                                                                    }>
                                                                        <option selected>select Thrillometer....
                                                                        </option>
                                                                        <option value="High">High</option>
                                                                        <option value="Medium">Medium</option>
                                                                    </select>
                                                                    <div style={{color: "red"}}>
                                                                        {thrillometerstate}
                                                                    </div>
                                                                    <hr />
                                                                    <div className="form-group form-default">
                                                                        <input
                                                                            type="number"
                                                                            name="footer-email"
                                                                            className="form-control"
                                                                            required
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                SetPrice(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <p className="float-label">
                                                                            Price
                                                                        </p>
                                                                        <div
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            {
                                                                                pricestate
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group form-default">
                                                                        <textarea
                                                                            className="form-control"
                                                                            required=""
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setDescription(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                            }}
                                                                            id="description"
                                                                        ></textarea>
                                                                        <p className="float-label">
                                                                            Text
                                                                            area
                                                                            Input
                                                                        </p>
                                                                        <div
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            {
                                                                                descriptionstate
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group form-default">
                                                                        <input
                                                                            type="file"
                                                                            name="image"
                                                                            className="form-control"
                                                                            required
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setImage(
                                                                                    e
                                                                                        .target
                                                                                        .files[0]
                                                                                );
                                                                            }}
                                                                        />
                                                                        {/* <p className="float-label">Image</p> */}
                                                                        <div
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            {
                                                                                imagestate
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-12"
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                handleClick()
                                                                            }
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="styleSelector"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddRidesPage;
