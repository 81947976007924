import React from "react";
import { Link } from "react-router-dom";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";
import "../AdminNavbar/Dashboard.css";
import { useState } from "react";
import { useEffect } from "react";
import {
  ACTIVETICKET,
  GRAPHCHART,
  ORDERCOUNT,
  RIDECOUNT,
  USERCOUNT,
} from "../../Service/service";
import { getData } from "../../Service/ApiMethod";
import { useNavigate } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
// import {  XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

// import BarChart from 'react-bar-chart';

function Dashboard() {
  const [resData, setResData] = useState();
  const [data, setData] = useState();
  const [order, setOrder] = useState();
  const [user, setUser] = useState();
  const navigate = useNavigate();

  //will have name key with months as value
  //value 1,2,3 will be ticket name and their value will be ticket_sold
  // const datafrom1 = [];
  // area chart
  const dataform = [];

  useEffect(() => {}, []);
  //   {
  //     name: "Jan 2023",
  //     value1: 100,
  //     value2: 150,
  //     value3: 200,
  //   },
  //   {
  //     name: "Feb",
  //     value1: 200,
  //     value2: 250,
  //     value3: 300,
  //   },
  //   {
  //     name: "Mar",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Apr",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "May",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Jun",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Jul",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Aug",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Sep",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Oct",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Nov",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  //   {
  //     name: "Dec",
  //     value1: 300,
  //     value2: 350,
  //     value3: 400,
  //   },
  // ];

  // bar chart
  const datas = [
    { month: "Jan", value: 400 },
    { month: "Feb", value: 300 },
    { month: "Mar", value: 200 },
    { month: "Apr", value: 278 },
    { month: "May", value: 189 },
    { month: "Jun", value: 239 },
    { month: "Jul", value: 349 },
    { month: "Aug", value: 378 },
    { month: "Sep", value: 239 },
    { month: "Oct", value: 400 },
    { month: "Nov", value: 300 },
    { month: "Dec", value: 200 },
  ];
  //Line chart
  const dataed = [
    { name: "Jan", value: 400 },
    { name: "Feb", value: 300 },
    { name: "Mar", value: 200 },
    { name: "Apr", value: 278 },
    { name: "May", value: 189 },
    { name: "Jun", value: 239 },
    { name: "Jul", value: 349 },
    { name: "Aug", value: 378 },
    { name: "Sep", value: 239 },
    { name: "Oct", value: 400 },
    { name: "Nov", value: 300 },
    { name: "Dec", value: 200 },
  ];

  //active rides
  function handleallrides() {
    navigate("/all-rides");
    window.location.reload();
  }

  // active tickets
  function handlealltickets() {
    navigate("/all-tickets");
    window.location.reload();
  }

  //active user
  function handlealluser() {
    navigate("/users");
    window.location.reload();
  }

  //active orders
  function handleallorders() {
    navigate("/order-list");
    window.location.reload();
  }
  // active ride api
  const getDetail = () => {
    getData(RIDECOUNT)
      .then((response) => response.json())
      .then((res) => {
        setResData(res);
      })

      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  // active ticket api

  const getTicket = () => {
    getData(ACTIVETICKET)
      .then((response) => response.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  // active orders api

  const getOrders = () => {
    getData(ORDERCOUNT)
      .then((response) => response.json())
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  // active user api
  const getUser = () => {
    getData(USERCOUNT)
      .then((response) => response.json())
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  const [chartData, setChartData] = useState();

  // Graph Chart API
  const GraphChart = () => {
    getData(GRAPHCHART)
      .then((response) => response.json())
      .then((res) => {
        // setGraphValue(res.data);
        // console.log("hereee", res.data);
        setChartData(res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  useEffect(() => {
    getDetail();
    getTicket();
    getOrders();
    getUser();
    GraphChart();
  }, []);

  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                                    <div className="page-block">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="page-header-title">
                                                    <p className="m-b-12">
                                                        <i
                                                         className="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                        ></i>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <ul className="breadcrumb-title">
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            {" "}
                                                            <i className="fa fa-home"></i>{" "}
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Bootstrap Tables
                                                        </Link>
                                                    </li>
                                                    <li className="breadcrumb-item">
                                                        <Link to={""}>
                                                            Basic Tables
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="pcoded-inner-content">
                                    <div class="main-body">
                                        <div class="page-wrapper">
                                            <div class="page-body">
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                        {resData != undefined ? (
                                                            <div class="card">
                                                                <div class="card-block">
                                                                    <div class="row align-items-center">
                                                                        <div class="col-8">
                                                                            <h4 class="text-c-purple">{resData.all_ride}</h4>
                                                                            <h6 class="text-muted m-b-0">Total Rides</h6>
                                                                        </div>
                                                                        <div class="col-4 text-right">
                                                                            <i class="fa fa-file-text-o f-28" style={{ fontSize: "28px" }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer" style={{ backgroundColor: "#536dfe" }}>
                                                                    <div class="row align-items-center">
                                                                        <div class="col-9">
                                                                            <p class="text-white m-b-0"> Active Rides : {resData.active_ride}</p>
                                                                        </div>
                                                                        <div class="col-3 text-right">
                                                                        <i class="fa fa-angle-double-right gobtn" style={{cursor:'pointer', fontSize: "27px", color: "#fff" }} onClick={handleallrides}></i>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>) : null}
                                                    </div>
                                                    <div class="col-xl-3 col-md-6">
                                                        {data != undefined ? (
                                                            <div class="card">
                                                                <div class="card-block">
                                                                    <div class="row align-items-center">
                                                                        <div class="col-8">
                                                                            <h4 class="text-c-green">{data.all_ticket}</h4>
                                                                            <h6 class="text-muted m-b-0">Total Ticket</h6>
                                                                        </div>
                                                                        <div class="col-4 text-right">
                                                                            <i class="fa fa-file-text-o f-28" style={{ fontSize: "28px" }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer hvr" style={{ backgroundColor: "#11c15b" }}>
                                                                    <div class="row align-items-center">
                                                                        <div class="col-9">
                                                                            <p class="text-white m-b-0">Active Ticket : {data.active_ticket}</p>
                                                                        </div>
                                                                        <div class="col-3 text-right">
                                                                            <i class="fa fa-angle-double-right gobtn" style={{cursor:'pointer', fontSize: "27px", color: "#fff" }} onClick={handlealltickets}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>) : null}
                                                    </div>
                                                    <div class="col-xl-3 col-md-6">
                                                        {user != undefined ? (
                                                            <div class="card">
                                                                <div class="card-block">
                                                                    <div class="row align-items-center">
                                                                        <div class="col-8">
                                                                            <h4 class="text-c-red">{user.user_list}</h4>
                                                                            <h6 class="text-muted m-b-0">Total User List</h6>
                                                                        </div>
                                                                        <div class="col-4 text-right">
                                                                            <i class="fa fa-file-text-o f-28" style={{ fontSize: "28px" }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer hvr" style={{ backgroundColor: "#ff5252" }}>
                                                                    <div class="row align-items-center">
                                                                        <div class="col-9">
                                                                            <p class="text-white m-b-0">Active User List : {user.active_user}</p>
                                                                        </div>
                                                                        <div class="col-3 text-right">

                                                                            <i class="fa fa-angle-double-right gobtn" style={{cursor:'pointer', fontSize: "27px", color: "white" }} onClick={handlealluser}></i>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>): null}
                                                    </div>
                                                    <div class="col-xl-3 col-md-6">
                                                        {order != undefined ? (
                                                            <div class="card">
                                                                <div class="card-block">
                                                                    <div class="row align-items-center">
                                                                        <div class="col-8">
                                                                            <h4 class="text-c-blue">{order.total_orders}</h4>
                                                                            <h6 class="text-muted m-b-0">Total Orders</h6>
                                                                        </div>
                                                                        <div class="col-4 text-right">
                                                                            <i class="fa fa-file-text-o f-28" style={{ fontSize: "28px" }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer hvr" style={{ backgroundColor: "#448aff" }}>
                                                                    <div class="row align-items-center">
                                                                        <div class="col-9">
                                                                            <p class="text-white m-b-0">Total Sale $ : {order.price}</p>
                                                                        </div>
                                                                        <div class="col-3 text-right">

                                                                        <i class="fa fa-angle-double-right gobtn" style={{cursor:'pointer', fontSize: "27px", color: "white" }} onClick={handleallorders}></i>
                                                                            

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>) : null}
                                                    </div>
                                                </div>
                                                <AreaChart width={1185} height={400} data={dataform}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                                                </AreaChart>
                                                
                                                <BarChart width={600} height={400} data={datas}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                </BarChart>

                        <LineChart width={600} height={400} data={dataed}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                          />
                        </LineChart>
                      </div>
                    </div>
                    <div id="styleSelector"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
