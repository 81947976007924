import React,{useState,useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import './ImageSlid.css'
import dataSlider from './DataSlider'
import BtnSlider from './BtnSlider'
import img1 from '../Imgs/l1.jpg'
import img2 from '../Imgs/l2.jpg'
import img3 from '../Imgs/l3.jpg'
import img4 from '../Imgs/l4.jpg'
import img5 from '../Imgs/l5.jpg'
// import img6 from '../Imgs/l6.jpg'
import leftarrow from "../ImageSlider/icons/leftarrow.png"
import rightarrow from "../ImageSlider/icons/rightarrow.png"
import moment from 'moment'


const ImageSlid = () => {

  let time= new Date().toLocaleTimeString();
  let date= new Date().toISOString().split("T")[0];

  const[ctime, setCTime] = useState(time)
  const[wish, setWish] = useState(time)



useState (()=>{
  const timeWish = new Date().getHours();
  if(timeWish < 12){
    setWish("Good Morning")
  }
  else if(timeWish > 11 && timeWish <15){
    setWish("Good After Noon")
  }
  else if(timeWish > 15 && timeWish < 19){
    setWish("Good Evening")
  }
  else{
    setWish("Good Evening")
  }

})



  const updateTime = () => {
    time=new Date().toLocaleTimeString();
    setCTime(time);
  };
  setInterval(updateTime, 1000);

  const [slideIndex, setSlideIndex] = useState(1)

  const nextSlide = () => {
      if(slideIndex !== dataSlider.length){
          setSlideIndex(slideIndex + 1)
      } 
      else if (slideIndex === dataSlider.length){
          setSlideIndex(1)
      }
  }

  const prevSlide = () => {
      if(slideIndex !== 1){
          setSlideIndex(slideIndex - 1)
      }
      else if (slideIndex === 1){
          setSlideIndex(dataSlider.length)
      }
  }

  const ref = useRef(null);
  const navigate = useNavigate()

const handleClick = () => {
  ref.current?.scrollIntoView({behavior: 'smooth'});
  navigate("/explore")
};

  return (
<>
    <div className='main-container'>

      <div className="in-container-carousel">

      <div id="carouselExampleAutoplaying" className="carousel" data-bs-ride="carousel">
      <div className="carousel-inner"  style={{ height: "100%" }}>
        <div className="carousel-item active">
          <img  src={img1} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img  src={img1} className="d-block w-100" alt="..."/>
        </div>
        <div className="carousel-item">
          <img src={img1} className="d-block w-100" alt="..."/>
        </div>
        
        
        <div className="carousel-item">
          <img  src={img1} className="d-block w-100" alt="..."/>
        </div>
        <div className="carousel-item">
          <img src={img1} className="d-block w-100" alt="..."/>
        </div>
        <div className="carousel-item">
          <img src={img1} className="d-block w-100" alt="..."/>
        </div>
       
    </div>
    <button className="carousel-control-prev" type="button"  data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
      {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
      {/* <span className="visually-hidden">Previous</span> */}
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
      {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
      {/* <span className="visually-hidden">Next</span> */}
    </button>
     
     <BtnSlider moveSlide={nextSlide} direction={"next"}  className="bttt"/>
      <BtnSlider moveSlide={prevSlide} direction={"prev"}/>
      </div>

      </div>



      <div className="in-container-info">

            <div className='date-container d-flex justify-content-center'>            
            <span style={{textDecoration:'underline', color:'red', fontWeight:'bold'}}><i>It's  {moment(date).format("dddd")} !</i></span>
            </div>
            <br />

          {/* <div><img src="https://res.cloudinary.com/simplotel/image/upload/x_-1,y_100,w_843,h_376,r_0,c_crop,q_80,dpr_1,f_auto,fl_progressive/w_355,h_200,f_auto,c_fit/wonderla-amusement-parks-resort/Light_Logo_njgie5" alt="logo.jpg" width="200px"  />
          </div> */}


            


            <div className='info'>
            Welcome to the entertainment industry with a new vision to assure an all-in-one entertainment spot for families.
            </div>
             
            <h4 className='dte'>Entry Time: <span className="text-danger">10:00 AM</span> </h4>
            <h4 className='dte'>Exit Time: &nbsp; <span className="text-danger">06:00 PM</span> </h4>
            <button className="explore-btn  " type="submit"  onClick={handleClick}>Explore</button>
             
          </div>
      </div>

  
  </>
  )
}

export default ImageSlid;