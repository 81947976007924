import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postDataImage } from "../../Service/ApiMethod";
import { ADDTICKETS, EDITDETAILS } from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";
import swal from "sweetalert";

function AddTicketsPage() {
  const location = useLocation();

  const locationData = location?.state?.item;
  console.log("locationData", locationData);

  const [heading, setHeading] = useState("");
  const [original_price, setOriginalPrice] = useState("");
  const [sale_price, setSalePrice] = useState("");
  const [image, setImage] = useState();
  const [description, setDescription] = useState("");

  // error validation
  const [errorstate, setErrorState] = useState("");
  const [headingstate, setHeadingState] = useState();
  const [descriptionstate, setDescriptionState] = useState();
  const [imageState, setImageState] = useState();
  const [originalpricestate, setOriginalPriceState] = useState();
  const [sale_pricestate, setSalePriceState] = useState("");
  const [conditionstate, setConditionState] = useState(true);

  useEffect(() => {
    if (!locationData) return;
    console.log("----->>>>>>>>>", locationData);
    setHeading(locationData.heading);
    setOriginalPrice(locationData.original_price);
    setSalePrice(locationData.sale_price);
    setDescription(locationData.description);
  }, [locationData]);
  const handleClick = async (e) => {
    setConditionState((value) => !value);

    // console.log("---", conditionstate);

    const url = new URL(window.location.href);

    if (url.searchParams.has("type")) {
      console.log("The query parameter is set");
      await postDataImage(
        {
          heading: heading,
          original_price: original_price,
          sale_price: sale_price,
          image: image,
          description: description,
        },
        EDITDETAILS + location?.state?.item.id
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("hiiiiiiiiiiiiiiiiiiiiiii", data);
          setHeading(data.heading);
          setOriginalPrice(data.original_price);
          setSalePrice(data.sale_price);
          setImage(data.image);
          setDescription(data.description);
          swal({
            title: "Ticket Updated!",
            text: "Ticket Updated  Successfuly",
            icon: "success",
            button: "OK",
          });
          navigateTo()
        })

        .catch((err) => {
          console.log("Error in categories from Post Form", err);
          console.log(" code Error", err);
        });
    } else {
      console.log("The query parameter is not set");
      setConditionState(
        await postDataImage(
          {
            heading: heading,
            original_price: original_price,
            sale_price: sale_price,
            image: image,
            description: description,
          },
          ADDTICKETS
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            try {
              const status = data.status;
              if (status === 401) {
                setErrorState(data.message);
              } else if (status === 200) {
                setErrorState(
                  swal({
                    title: "Ticket Added!",
                    text: "Ticket Added Successfuly",
                    icon: "success",
                    button: "OK",
                  })
                );
                window.location.reload();
              } else if (status === undefined && data.stauts === 401) {
                setDescriptionState(data.errors.description);
                setHeadingState(data.errors.heading);
                setImageState(data.errors.image);
                setOriginalPriceState(data.errors.original_price);
                setSalePriceState(data.errors.sale_price);
              }
            } catch (error) {
              console.log("---", error);
            }
          })
      );
    }
  };

  const move = useNavigate()
  const navigateTo = () => {
    move("/all-tickets")
    window.location.reload()
  } 
  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          {/* top bar comes here  tag name nav  */}
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-header">
                                <h5> Add Tickets</h5>
                              </div>
                              <div className="card-block">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "red",
                                  }}
                                >
                                  {errorstate}
                                </div>
                               
                                <form className="form-material">
                                  {/* <div className="form-group form-default">
                                                                        <input type="text" className="form-control"
                                                                            value={0}
                                                                            hidden
                                                                        />
                                                                    </div> */}
                                  <div className="form-group form-default">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={heading}
                                      onChange={(e) => {
                                        setHeading(e.target.value);
                                      }}
                                    />
                                    <p className="float-label">Title</p>
                                    <div style={{ color: "red" }}>
                                      {headingstate}
                                    </div>
                                  </div>
                                  <div className="form-group form-default">
                                    <input
                                      type="text"
                                      name="original_price"
                                      className="form-control"
                                      value={original_price}
                                      onChange={(e) => {
                                        setOriginalPrice(e.target.value);
                                      }}
                                    />
                                    <p className="float-label">
                                      Original Price
                                    </p>
                                    <div style={{ color: "red" }}>
                                      {originalpricestate}
                                    </div>
                                  </div>
                                  <div className="form-group form-default">
                                    <input
                                      type="text"
                                      name="sale_price"
                                      className="form-control"
                                      value={sale_price}
                                      onChange={(e) => {
                                        setSalePrice(e.target.value);
                                      }}
                                    />
                                    <p className="float-label">Sale Price</p>
                                    <div style={{ color: "red" }}>
                                      {sale_pricestate}
                                    </div>
                                  </div>

                                  <div className="form-group form-default">
                                    <textarea
                                      name="description"
                                      className="form-control"
                                      value={description}
                                      onChange={(e) => {
                                        setDescription(e.target.value);
                                      }}
                                    ></textarea>
                                    <p className="float-label"> Description</p>
                                    <div style={{ color: "red" }}>
                                      {descriptionstate}
                                    </div>
                                  </div>
                                  <div className="form-group form-default">
                                    <input
                                      type="file"
                                      name="image"
                                      className="form-control"
                                      // value={image}
                                      onChange={(e) => {
                                        setImage(e.target.files[0]);
                                      }}
                                    />
                                    <p className="float-label">Image</p>
                                    <div style={{ color: "red" }}>
                                      {imageState}
                                    </div>
                                  </div>
                                  <div
                                    className="col-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      checked={conditionstate}
                                      onClick={handleClick}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTicketsPage;
