import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { set } from "react-hook-form";
import Moment from "react-moment";
import { Link, Route, useNavigate } from "react-router-dom";
import { getData, postData } from "../../Service/ApiMethod";
import {
  ADMINGETRIDE,
  ADMINTICKETMANAGE,
  ALLTICKETS,
  baseUrl,
  PRICEATLOGIN,
  SEARCHBAR,
} from "../../Service/service";
import SideNavbar from "../AdminNavbar/SideNavbar";
import Topbar from "../AdminNavbar/Topbar";
import swal from "sweetalert";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
function AllTickets() {
  const [userData, setUserData] = useState([]);
  const [vacancyId, setVaccancyId] = useState();
  const [change, setChange] = useState(0);
  const [filterdata, setFilterData] = useState([]);
  const [query, setQuery] = useState();
  const [imageLink, setImageLink] = useState();
  const [modal, setModal] = useState(false);
  const [rideDetail, setRideDetail] = useState();
  const imageUrl =baseUrl+`/backend/tickets/`;
  // const imageUrl =   baseUrl+`/public/backend/tickets/`; //dev server 
  
  const navigate = useNavigate();

  const popup = (id, status) => {
    console.log(status);
    const newStatus = status === "1" ? "0" : "1";
    console.log(id);
    let objItem = {
      status: newStatus,
      ticket_id: id,
      _method: "PUT",
    };
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to change the ticket Status?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //post api
        postData(objItem, ADMINTICKETMANAGE)
          .then((response) => response.json())
          .then((res) => {
            console.log(res);
            if (res.status === 500) {
              swal("Status!", res.message, "error");
            } else {
              swal("Status!", "Your ticket status changed", "success");
            }
            window.location.reload();

            //change button if active change to inactive button else viceversa
          })
          .catch((err) => {
            console.log("Error in categories from Post Form", err);
            console.log(" code Error", err);
            swal("Status!", "Retry !", "error");
          });
      }
    });
  };

  const handleTicketDeatail = (id) => {
    navigate(`/all-tickets/ticketlist/${id}`);
    window.location.reload();
  };

  const handleEditDetail = (item) => {
    navigate("/add-tickets?type=edit", { state: { item } });
    window.location.reload();
  };

  const getDetail = (e) => {
    // e.preventDefault()
    getData(ALLTICKETS)
      .then((response) => response.json())
      .then((res) => {
        setUserData(res.data);
        // console.log("-->>>>>>>>>>", res.data);
        setImageLink(res.data?.list?.image);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };
  console.log("image", imageLink);
  console.log("combineImageUrl", imageUrl + imageLink);
  const handlesearch = (event) => {
    const getsearch = event.target.value;
    if (getsearch.length > 0) {
      getData(`${SEARCHBAR}?search=${getsearch}`)
        .then((response) => response.json())
        .then((res) => {
          setUserData(res.data);
          // console.log(res.data);
          setFilterData(res.data);
        });
      const searchdata = userData.filter((item) =>
        item.heading.toLowerCase().includes(getsearch)
      );
      setUserData(searchdata);
    } else {
      getDetail();
    }
    setQuery(getsearch);
  };

  useEffect(() => {
    // getSearch()
    getDetail();
    // popup()
  }, []);
  // const [price, setPrice] = useState();
  const handleView = (id, price_at_login) => {
    const newStatus1 = price_at_login === "1" ? "0" : "1";

    let dataObj = {
      price_at_login: newStatus1,
      ticket_id: id,
    };

    console.log("asdasd", dataObj);
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to change the ticket status?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postData(dataObj, PRICEATLOGIN)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            swal("Status!", "Your ticket status changed", "success");
            window.location.reload();
          })
          .catch((err) => {
            console.log("Error in categories from Post Form", err);
            console.log(" code Error", err);
            swal("Status!", "Retry !", "error");
          });
      }
    });
  };
  useEffect(() => {
    // handleView();
  }, []);

  const getRideDetail = (id) => {
    getData(SEARCHBAR +'?id=' + id)
      .then((response) => response.json())
      .then((res) => {
        setRideDetail(res.data);
      })
      .catch((err) => {
        console.log("Error in categories from Post Form", err);
        console.log(" code Error", err);
      });
  };

  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <Topbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Bootstrap Tables</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>Basic Tables</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <Modal size="s" isOpen={modal} toggle={() => setModal(!modal)}>
                  <ModalHeader style={{ position: "relative" }}>
                    Image Detail
                    <button
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "0rem",
                      }}
                      class="close-btn border-0"
                      onClick={() => setModal(false)}
                    >
                      <i class="fa fa-close"></i>
                    </button>
                  </ModalHeader>
                  {rideDetail !== undefined ? 
                  <ModalBody>
                    <div className="row">
                      <div className="col-6">
                      
                        <img
                          style={{width:"29rem"}}
                          src={imageUrl + rideDetail.image}
                          alt="Event-Profile-Image"
                        />
                      </div>
                    </div>
                  </ModalBody> 
                  : null}
                </Modal>
                <div className="p-30 p-b-5">
                  <label className="float-label" style={{ fontsize: "5px" }}>
                    <i className="fa fa-search m-r-10"></i>
                    Search Ticket
                  </label>
                  <form className="form-material">
                    <div className="form-group form-primary">
                      <input
                        type="text"
                        name="name"
                        value={query}
                        className="form-control"
                        placeholder="Search by name or ID..."
                        onChange={(e) => handlesearch(e)}
                      />

                      <span className="form-bar"></span>
                    </div>
                  </form>
                </div>
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="page-body">
                      <div className="card">
                        <div className="card-header">
                          <h5>Tickets List</h5>
                          
                        </div>
                        <div className="card-block table-border-style">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Title</th>
                                  <th>Original Price</th>
                                  <th>Sale Price</th>
                                  <th>Discount</th>
                                  <th>Image</th>
                                  <th>Created</th>
                                  {/* <th>Updated</th> */}
                                  <th
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              {userData !== undefined
                                ? userData.map((item, index) => {
                                    const combineImageUrl =
                                      imageUrl + "/" + item?.image;
                                    
                                    return (
                                      <tbody key={index}>
                                        <tr>
                                          <td scope="row">{item?.id}</td>
                                          <td>{item.heading}</td>
                                          <td>{item.original_price}</td>
                                          <td>{item.sale_price}</td>
                                          <td>{item.discount}</td>
                                          <td>
                                            <img
                                              src={combineImageUrl}
                                              alt=""
                                              style={{ width: "8rem" }}
                                              onClick={() => {
                                                setModal(true);
                                                getRideDetail(
                                                            item.id
                                                          );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <Moment format="YYYY/MM/DD">
                                              {item.created_at}
                                            </Moment>
                                          </td>
                                          {/* <td>
                                            <Moment format="YYYY/MM/DD">
                                              {item.updated_at}
                                            </Moment>
                                          </td> */}
                                          <td>
                                            {item.status == 0 ? (
                                              <span
                                                className="btn  btn-primary btn-icon m-2 pl-2"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <p className="mytooltip tooltip-effect-5">
                                                  <i
                                                    className="ti-control-play"
                                                    onClick={() =>
                                                      popup(
                                                        item.id,
                                                        item.status
                                                      )
                                                    }
                                                  ></i>
                                                  <span className="tooltip-content clearfix">
                                                    <span
                                                      className="tooltip-text"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {" "}
                                                      Click to Active
                                                    </span>
                                                  </span>
                                                </p>
                                              </span>
                                            ) : (
                                              <span
                                                className="btn  btn-primary btn-icon m-2 pl-2 "
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <p className="mytooltip tooltip-effect-5">
                                                  <i
                                                    className="ti-control-pause"
                                                    onClick={() =>
                                                      popup(
                                                        item.id,
                                                        item.status
                                                      )
                                                    }
                                                  ></i>
                                                  <span className="tooltip-content clearfix">
                                                    <span
                                                      className="tooltip-text"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Click to Deactive
                                                    </span>
                                                  </span>
                                                </p>
                                              </span>
                                            )}

                                            <span
                                              className="btn  btn-primary btn-icon m-2 pl-2"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <p className="mytooltip tooltip-effect-5">
                                                <i
                                                  className="ti-pencil-alt"
                                                  onClick={() => {
                                                    handleEditDetail(item);
                                                  }}
                                                />
                                                <span className="tooltip-content clearfix">
                                                  <span
                                                    className="tooltip-text"
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edit
                                                    Tickets
                                                  </span>
                                                </span>
                                              </p>
                                            </span>
                                            <span
                                              className="btn  btn-primary btn-icon m-2 pl-2"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <p className="mytooltip tooltip-effect-5">
                                                <i
                                                  className="ti-plus"
                                                  onClick={(e) => {
                                                    handleTicketDeatail(
                                                      item.id
                                                    );
                                                  }}
                                                />
                                                <span className="tooltip-content clearfix">
                                                  <span
                                                    className="tooltip-text"
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    &nbsp;&nbsp;&nbsp;Assign
                                                    Rides
                                                  </span>
                                                </span>
                                              </p>
                                            </span>
                                            {item.price_at_login == 1 ? (
                                              <span
                                                className="btn  btn-primary btn-icon m-2 pl-2"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <p className="mytooltip tooltip-effect-5">
                                                  <i
                                                    className="bi bi-person-check-fill"
                                                    //   value={price_at_login}

                                                    onClick={(e) => {
                                                      handleView(
                                                        item.id,
                                                        item.price_at_login
                                                      );
                                                    }}
                                                  />
                                                  <span className="tooltip-content clearfix">
                                                    <span
                                                      className="tooltip-text"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Hide Price
                                                    </span>
                                                  </span>
                                                </p>
                                              </span>
                                            ) : (
                                              <span
                                                className="btn  btn-primary btn-icon m-2 pl-2"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <p className="mytooltip tooltip-effect-5">
                                                  <i
                                                    className="bi bi-person-exclamation"
                                                    //   value={price_at_login}

                                                    onClick={(e) => {
                                                      // setPrice(e.target.value);

                                                      handleView(
                                                        item.id,
                                                        item.price_at_login
                                                      );
                                                    }}
                                                  />
                                                  <span className="tooltip-content clearfix">
                                                    <span
                                                      className="tooltip-text"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      Show Price
                                                    </span>
                                                  </span>
                                                </p>
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                : null}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="styleSelector"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTickets;
