import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SubAdminSideNavbar from './SubAdminSideNavbar'
import SubAdminTopbar from './SubAdminTopbar'


function SubAdminDeshboard() {
  // const [isSubAdmin, setSubAdmin] = useState(false);
  // useEffect(() => {
  //   if (
  //     localStorage.getItem("fun_park_token") &&
  //     localStorage.getItem("email")
  //   ) {
  //     setSubAdmin(true);
  //   } else {
  //     setSubAdmin(false);
  //   }
  // }, []);
  // console.log("subadmin", isSubAdmin);
  return (
    <>
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <SubAdminTopbar />
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <SubAdminSideNavbar />
              <div className="pcoded-content">
                {/* <div className="page-header">
                  <div className="page-block">
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="page-header-title">
                          <p className="m-b-12">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ul className="breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              {" "}
                              <i className="fa fa-home"></i>{" "}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              Bootstrap Tables
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={""}>
                              Basic Tables
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {isSubAdmin === true ? ( */}
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="card">
                          <div className="card-header">
                            <h5>
                              Confirm Ticket
                            </h5>
                          </div>
                          <div className="row card-header">
                            <div
                              className="col-3"
                              style={{
                                display:
                                  "flex",
                                alignItems:
                                  "center",
                                paddingLeft:
                                  "71px",
                              }}
                            >
                              <div className="row">
                                <form className="form-material">
                                  <div className="col-9 ">
                                    <div className="form-group form-primary">
                                      <input
                                        type="text"
                                        className="form-control pl-1"
                                        placeholder="Order Id"
                                      />
                                      <span className="form-bar"></span>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <button className="btn btn-primary">
                                      Check
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div
                              className="col-9 p-3"
                              style={{
                                background:
                                  "#DADDE6",
                              }}
                            >
                              <article className="card-ticket fl-left">
                                <section className="date">
                                  <time datetime="23th feb">
                                    <span>
                                      23
                                    </span>
                                    <span>
                                      feb
                                    </span>
                                  </time>
                                </section>
                                <section className="card-cont-1">
                                  <small>
                                    Tarun
                                  </small>
                                  <br />
                                  <small>
                                    M.No
                                    :
                                    12346567890
                                  </small>
                                  <h3 className="text-danger">
                                    Fun
                                    with
                                    family
                                  </h3>
                                  <div className="even-date">
                                    <i className="fa fa-calendar"></i>

                                    <time
                                      style={{
                                        padding:
                                          "7px",
                                      }}
                                    >
                                      <span>
                                        wednesday
                                        07
                                        feb
                                        2023
                                      </span>
                                      <span>
                                        08:55pm
                                        to
                                        12:00
                                        am
                                      </span>
                                    </time>
                                  </div>
                                  <div className="even-info">
                                    {/* <i className="fa fa-map-marker"></i> */}
                                    <i class="ti-ticket"></i>
                                    <p
                                      style={{
                                        fontWeight:
                                          "bold",
                                      }}
                                    >
                                      OrderID:
                                      ORFUN001
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      TicketID:
                                      TID001
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      Status
                                      :{" "}
                                      <label class="label label-success">
                                        Used
                                      </label>
                                    </p>
                                  </div>

                                  <p>
                                    booked
                                  </p>
                                </section>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="styleSelector"></div>
                </div>
                {/* ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubAdminDeshboard
